import { apiSlice } from "../api/apiSlice";
export const DailyPrizeListApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDailyPrizeList: builder.query({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/prizes/daily-prize-list/",
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getMedalList: builder.query({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/prizes/medal-list",
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getBadgesList: builder.query({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/prizes/badge-list",
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});
export const {
  useGetDailyPrizeListQuery,
  useGetMedalListQuery,
  useGetBadgesListQuery,
} = DailyPrizeListApi;
