import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import lefticon from "../../assets/images/Group11246.png";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useLoginMutation } from "../../features/auth/authAPI";
import { setCookies } from "../../utils/Cookies";
import LoadingPage from "../Shared/LoadingPage/LoadingPage";
import Subtract from "../../assets/images/Subtract.png";
import {
  signInWithFacebook,
  signInWithGoogle,
} from "../../features/auth/authSlice";
const LoginPage = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [formData1, setFormData1] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [login, { data, isLoading, error: responseError, isSuccess }] =
    useLoginMutation();
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  useEffect(() => {
    if (data) {
      setCookies("refreshToken", data.refresh);
      setCookies("accessToken", data.access);
      navigate("/ChallengePages");
    }
    console.log("form dta");
  }, [data]);
  useEffect(() => {
    if (Object.keys(formData1).every((key) => formData1[key])) {
      login(formData1);
    }
  }, [formData1, login]);
  const submitHandler = (e) => {
    e.preventDefault();
    login(formData);
  };
  if (isLoading) {
    return <LoadingPage />;
  }

  const LoginWithFaceBook = () => {
    signInWithFacebook()
      .then((result) => {
        setFormData1({
          email: result.user.email,
          password: process.env.REACT_APP_password,
        });
      })
      .catch((error) => {
        console.error("Apple sign-in error", error);
      });
  };
  const LoginWithGoogle = () => {
    console.log("google");
    signInWithGoogle()
      .then((result) => {
        console.log("userdata", result.user);
        setFormData1({
          email: result.user.email,
          password: process.env.REACT_APP_password,
        });
        
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className="flex flex-col lg:flex-row h-[120vh] overflow-hidden">
      <div
        className="hidden lg:block flex-1 bg-no-repeat bg-cover h-full w-full"
        style={{
          backgroundImage: `url(${Subtract})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex items-center justify-center h-full text-white text-3xl text-center font-noto-sans">
          <div className="transform lg:transform-none">
            <h1 className="font-bold ml-[-200px]">Welcome to the</h1>
            <h1 className="font-bold ml-[-120px]">Happy Nation Games</h1>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center bg-[#fffbf4]">
        <div className="relative bg-[#fffbf4] w-[90vw] max-w-[350px] flex flex-col items-center p-4 lg:p-0">
          <Link to={"/"}>
            <img
              src={lefticon}
              alt="back icon"
              className="h-8 w-8 absolute top-0 left-0  mt-[-50px]"
            />
          </Link>
          <p className="text-[#070707] text-2xl font-medium text-center mt-0 mb-6 lg:mb-6">
            Sign in with your account
          </p>
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-center w-full"
          >
            <input
              type="email"
              id="email"
              required
              placeholder="Email"
              onChange={handleInputChange}
              className="w-11/12 border-b border-[#f17430] py-2 mb-8"
            />
            <input
              type="password"
              id="password"
              required
              placeholder="Password"
              onChange={handleInputChange}
              className="w-11/12 border-b border-[#f17430] py-2 mb-8"
            />
            <a href="./" className="text-[#1d1d1f] text-sm mb-10 lg:mb-12">
              Forgot your password?
            </a>
            <button
              disabled={isLoading}
              className="flex justify-center items-center w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg mb-16"
            >
              Sign in
            </button>
          </form>
          <div className="flex items-center justify-between w-80 mb-6 lg:mb-8">
            <div className="h-px w-2/5 bg-[#f17430]" />
            <p className="text-[#1d1d1f] font-medium">or</p>
            <div className="h-px w-2/5 bg-[#f17430]" />
          </div>
          <div className="flex justify-between items-center w-full px-8">
            <FacebookIcon
              onClick={LoginWithFaceBook}
              fontSize="medium"
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
            <GoogleIcon
              onClick={LoginWithGoogle}
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
            <AppleIcon
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
          </div>
          <div className="flex flex-col items-center mt-8">
            <p className="text-[#1d1d1f] text-base mb-2">
              New here?{" "}
              <Link
                to="/signup1"
                className="text-[#f17430] text-base font-medium underline"
              >
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
