import { Link } from "react-router-dom";
import Cover from "../../assets/images/coverimage.png";
import LoadingPage from "../Shared/LoadingPage/LoadingPage";
import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import lefticon from "../../assets/images/Group11246.png";
import { useEffect, useRef, useState } from "react";
import Subtract from "../../assets/images/Subtract.png";
const Welcomepage = () => {
  const [loadingTime, setLoadingTime] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoadingTime(true);
    }, 2500);
  }, []);

  useEffect(() => {
    fetch("https://gamedemoserver.happynation.global/accounts/get-countries/")
      .then((response) => response.json())
      .then((json) => console.log("json", json));
  }, []);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div>
      {loadingTime ? (
        isDesktop ? (
          <div className="flex flex-col lg:flex-row h-[120vh] overflow-hidden">
            <div
              className="hidden lg:block flex-1 bg-no-repeat bg-cover h-full w-full"
              style={{
                backgroundImage: `url(${Subtract})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
              }}
            >
              <div className="flex items-center justify-center h-full text-white text-3xl text-center font-noto-sans">
                <div className="transform lg:transform-none">
                  <h1 className="font-bold ml-[-200px]">Welcome to the</h1>
                  <h1 className="font-bold ml-[-120px]">Happy Nation Games</h1>
                </div>
              </div>
            </div>
            <div className="flex-1 flex items-center justify-center bg-[#fffbf4]">
              <div className="flex flex-col items-center mb-20 md:mb-32 lg:mb-16">
                <Link
                  to={"/signUp1"}
                  className="flex justify-center items-center w-64 sm:w-72 md:w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out"
                >
                  Get Started
                </Link>
                <p className="mt-3 text-orange-500 text-center text-base md:text-lg lg:text-xl">
                  Already have an account?
                  <Link to={"login"} className="underline ml-2 text-orange-500">
                    Sign in
                  </Link>
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="flex flex-col justify-between bg-center overflow-hidden w-full h-[110vh] bg-no-repeat bg-cover lg:bg-repeat-lg"
            style={{ backgroundImage: `url(${Cover})` }}
          >
            <div className="flex flex-col items-center pt-20 md:pt-32 lg:pt-48">
              <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center text-white">
                Welcome to the <br /> Happy Nation Games
              </h1>
            </div>
            <div className="flex flex-col items-center mb-20 md:mb-32 lg:mb-16">
              <Link
                to={"/signUp1"}
                className="flex justify-center items-center w-64 sm:w-72 md:w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out"
              >
                Get Started
              </Link>
              <p className="mt-3 text-white text-center text-base md:text-lg lg:text-xl">
                Already have an account?
                <Link to={"login"} className="underline ml-2 text-white">
                  Sign in
                </Link>
              </p>
            </div>
          </div>
        )
      ) : (
        <LoadingPage />
      )}
    </div>
  );
};

export default Welcomepage;
