import { useNavigate } from "react-router-dom";
import "./BodyChallenge.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useEffect, useState } from "react";
//import physicalImage from "../../../../assets/images/PDB21.png";
import physicalImage from "../../../../assets/images/PDB1 472x306.png";
import phyMobile from "../../../../assets/images/physical.png"
const BodyChallenge = ({ data }) => {
  const navigate = useNavigate();
  const checkClick = () => {
    navigate("/ChallengePages/physical-challenge-detail", {
      state: { PhysicalChallengeData: data },
    });
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return isDesktop ? (
    <div onClick={() => checkClick()} className="card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeBodyChallengeDesktop flex flex-col-reverse ">
      <img
        src={physicalImage}
        alt="Movie"
        className="BodyChallengeImageDesktop"
      />
      <div className="card-body cardLeftBodyChallengeDesktop">
        <h2 className="card-title text-white">Physical</h2>
        <div className="card-actions mt-1">
          <div className="flex items-center gap-20">
            <h2 className="text-white mt-2">Full body workout</h2>
            <ArrowForwardIosOutlinedIcon
              
              style={{ color: "white" }}
              className="ArrowRightBodyChallenge mt-[-3rem]"
            ></ArrowForwardIosOutlinedIcon>
          </div>
        </div>
        {/* <div className="flex flex-col justify-between  ArrowLeftDesktop">
          <ArrowForwardIosOutlinedIcon
            onClick={() => checkClick()}
            style={{ color: "white" }}
            className="ArrowRight"
          ></ArrowForwardIosOutlinedIcon>
        </div> */}
      </div>
    </div>
  ) : (
    <div className="card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeBodyChallenge">
      <img
        src="https://c.animaapp.com/QJzwa7Iu/img/mask-group-2@2x.png"
        alt="Movie"
        className="BodyChallengeImage"
      />
      <div
        onClick={() => checkClick()}
        className="card-body cardLeftBodyChallenge"
      >
        <h2 className="card-title text-white">Physical</h2>
        <div className="card-actions mt-[-.5rem]">
          <h2 className="text-white">Full body workout</h2>
        </div>
        <div className="flex flex-col justify-between  ArrowLeft">
          <ArrowForwardIosOutlinedIcon
            style={{ color: "white" }}
            className="ArrowRight"
          ></ArrowForwardIosOutlinedIcon>
        </div>
      </div>
    </div>
  );
};
export default BodyChallenge;
