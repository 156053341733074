import { DayPicker, Row } from "react-day-picker";
import { useGetEventListQuery } from "../../features/EventList/EventListAPI";
import { getCookies } from "../../utils/Cookies";
import "./EventPage.css";
import { useDispatch, useSelector } from "react-redux";
import { challengeDay } from "../../features/challenge/challengeSlice";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import {
  endOfWeek,
  format,
  isFuture,
  isWithinInterval,
  startOfToday,
  startOfWeek,
  parseISO,
} from "date-fns";
import { useEffect, useState } from "react";
import AccessTimeFilledOutlinedIcon from "@mui/icons-material/AccessTimeFilledOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { useUserInfoQuery } from "../../features/auth/authAPI";
import { LoggedInUserInfoSuccess } from "../../features/auth/authSlice";
import dumbel from "../../assets/images/Group 11188.png";
const css = `
  .my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid currentColor;
  }
  .my-selected:hover:not([disabled]) { 
    border-color: blue;
    color: blue;
  }
  .my-today { 
    font-weight: bold;
    font-size: 140%; 
    color: #f17430;
  }
  .with-dot {
    position: relative;
  }
  .with-dot::before {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    background-color: #f17430;
    border-radius: 50%;
  }
`;
const EventPage = () => {
  const accessCode = getCookies("accessToken");
  const data = {
    token: accessCode,
  };
  const [eventDataState, setEventDataState] = useState();
  const [comingUpEventData, setComingUpEvent] = useState();
  const { data: eventData, error, isLoading } = useGetEventListQuery(data);
  const {
    data: userData,
    error: userInfoError,
    isLoading: userInfoLoading,
  } = useUserInfoQuery(data);
  const dispatch = useDispatch();
  const [userDataSet, setUserData] = useState();
  const [userEventDataSet, setUserEventDataSet] = useState([]);
  useEffect(() => {
    if (userData) {
      setUserData(userData);
      dispatch(LoggedInUserInfoSuccess(userData));
      setUserEventDataSet(userData?.events);
    }
  }, [userData]);

  const [dates, setDates] = useState();
  useEffect(() => {
    console.log(eventData);
    setEventDataState(eventData);
    const tempDate = [];
    const tempComingUpEventData = [];
    if (eventDataState?.length) {
      eventDataState.map((data) => {
        tempDate.push(data?.date);
        if (isFuture(parseISO(data?.date))) {
          tempComingUpEventData.push(data);
        }
      });
    }
    setDates(tempDate);
    setComingUpEvent(tempComingUpEventData);
  }, [eventData, eventDataState]);
  const challengeData = useSelector(challengeDay);
  function CurrentWeekRow(props) {
    const isDateInCurrentWeek = (dateToCheck) => {
      const today = new Date();
      const start = startOfWeek(today);
      const end = endOfWeek(today);
      return isWithinInterval(dateToCheck, { start, end });
    };
    const isNotCurrentWeek = props.dates.every(
      (date) => !isDateInCurrentWeek(date)
    );
    if (isNotCurrentWeek) return <></>;
    return <Row {...props} />;
  }
  const [calenderArrow, setCalenderArrow] = useState(false);
  const changeArrow = () => {
    setCalenderArrow(!calenderArrow);
  };
  const modifiers = {
    withDot: dates?.map((date) => new Date(date)),
  };
  const [allEventClick, setallEventClick] = useState(true);
  const [comingUpEventClick, setcomingUpEventClick] = useState(false);
  const [SignedUpClick, setSignedClick] = useState(false);
  const ChangeCLick = (parameter) => {
    if (parameter === "allEventClick") {
      setallEventClick(true);
      setcomingUpEventClick(false);
      setSignedClick(false);
    } else if (parameter === "comingUpEventClick") {
      setallEventClick(false);
      setcomingUpEventClick(true);
      setSignedClick(false);
    } else if (parameter === "SignedUpClick") {
      setallEventClick(false);
      setcomingUpEventClick(false);
      setSignedClick(true);
    }
  };
  const navigate = useNavigate();
  const handleDetailsEvent = (id) => {
    navigate(`/EventPage/${id}`);
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return isDesktop ? (
    <div className="mt-[7rem] mb-[10rem] flex justify-between mr-8 ">
      <div className="ml-[12rem]">
        <h1 className="font-sans font-medium text-xl">
          Challenge {challengeData?.day}
        </h1>
        <div className="mt-8">
          <div className="max-w-xl bg-[#F9F6F2] border shadow-xl border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <DayPicker
              showOutsideDays
              disableNavigation
              mode="single"
              modifiers={modifiers}
              modifiersClassNames={{
                selected: "my-selected",
                today: "my-today",
                withDot: "with-dot",
              }}
              modifiersStyles={{
                disabled: { fontSize: "90%" },
              }}
              styles={{
                root: {
                  fontSize: "1.5rem", // Increased font size
                },
                day: {
                  padding: "0.75rem", // Increased padding
                  margin: "0.5rem", // Increased margin
                },
                caption: {
                  display: "none",
                },
                months: {
                  padding: "1.5rem", // Increased padding
                },
                today: {
                  color: "#F17430", // Change the color for the date
                },
                weekday: {
                  color: "#F17430", // Change the color for the name of the week
                },
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col mr-[8rem] mt-[4rem]">
        <nav aria-label="Page navigation example">
          <ul className="inline-flex -space-x-px text-base h-10">
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("allEventClick")}
                className={`${
                  allEventClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 w-[8rem] ms-0 leading-tight border-e-0 border border-gradient-orange-red  hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                All
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("comingUpEventClick")}
                className={`${
                  comingUpEventClick
                    ? `text-white rectangleIntense`
                    : `text-red`
                } flex items-center justify-center px-4 h-10 w-[8rem] ms-0 leading-tight border-e-0 border border-gradient-orange-red hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                Upcoming
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("SignedUpClick")}
                className={`${
                  SignedUpClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 w-[8rem] ms-0 leading-tight border border-gradient-orange-red  hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white w-[6.85rem]`}
              >
                Signed Up
              </a>
            </li>
          </ul>
        </nav>
        {allEventClick &&
          eventDataState?.map((data) => (
            <div
              onClick={() => handleDetailsEvent(data?.id)}
              className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeDesktop"
            >
              <figure>
                <img src={data?.image} alt="Movie" />
              </figure>
              <div className="card-body cardLeft">
                <h2 className="card-title text-white">{data?.title}</h2>
                <div className="card-actions mt-2">
                  <AccessTimeFilledOutlinedIcon
                    style={{ color: "white" }}
                  ></AccessTimeFilledOutlinedIcon>
                  <h2 className="text-white">
                    {data?.start_time.substring(0, 5)} -{" "}
                    {data?.end_time.substring(0, 5)}
                  </h2>
                </div>
                <h1 className="text-white flex flex-row gap-2">
                  <img src={dumbel} className="w-5 h-5"></img>
                  {data?.name}
                </h1>
                <div className="flex flex-col justify-between  ArrowLeftDesktop">
                  {userEventDataSet && userEventDataSet.includes(data?.id) ? (
                    <NotificationsIcon
                      style={{ color: "white" }}
                      className="BellArrow"
                    />
                  ) : null}
                  <ArrowForwardIosOutlinedIcon
                    style={{ color: "white" }}
                    className="ArrowRight"
                  ></ArrowForwardIosOutlinedIcon>
                </div>
              </div>
            </div>
          ))}
        {comingUpEventClick &&
          comingUpEventData?.map((data) => (
            <div
              onClick={() => handleDetailsEvent(data?.id)}
              className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeDesktop"
            >
              <figure>
                <img src={data?.image} alt="Movie" />
              </figure>
              <div className="card-body cardLeft">
                <h2 className="card-title text-white">{data?.title}</h2>
                <div className="card-actions mt-2">
                  <AccessTimeFilledOutlinedIcon
                    style={{ color: "white" }}
                  ></AccessTimeFilledOutlinedIcon>
                  <h2 className="text-white">
                    {data?.start_time.substring(0, 5)} -{" "}
                    {data?.end_time.substring(0, 5)}
                  </h2>
                  <h1 className="text-white flex flex-row gap-2">
                    <img src={dumbel} className="w-5 h-5"></img>
                    {data?.name}
                  </h1>
                </div>
                <div className="flex flex-col justify-between  ArrowLeftDesktop">
                  {userEventDataSet && userEventDataSet.includes(data?.id) ? (
                    <NotificationsIcon
                      style={{ color: "white" }}
                      className="BellArrow"
                    />
                  ) : null}
                  <ArrowForwardIosOutlinedIcon
                    style={{ color: "white" }}
                    className="ArrowRight"
                  ></ArrowForwardIosOutlinedIcon>
                </div>
              </div>
            </div>
          ))}
        {SignedUpClick &&
          eventDataState
            ?.filter((data) => userEventDataSet.includes(data?.id))
            .map((data) => (
              <div
                onClick={() => handleDetailsEvent(data?.id)}
                className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeDesktop"
              >
                <figure>
                  <img src={data?.image} alt="Movie" />
                </figure>
                <div className="card-body cardLeft">
                  <h2 className="card-title text-white">{data?.title}</h2>
                  <div className="card-actions mt-2">
                    <AccessTimeFilledOutlinedIcon
                      style={{ color: "white" }}
                    ></AccessTimeFilledOutlinedIcon>
                    <h2 className="text-white">
                      {data?.start_time.substring(0, 5)} -{" "}
                      {data?.end_time.substring(0, 5)}
                    </h2>
                  </div>
                  <h1 className="text-white flex flex-row gap-2">
                    <img src={dumbel} className="w-5 h-5"></img>
                    {data?.name}
                  </h1>
                  <div className="flex flex-col justify-between  ArrowLeftDesktop">
                    {userEventDataSet && userEventDataSet.includes(data?.id) ? (
                      <NotificationsIcon
                        style={{ color: "white" }}
                        className="BellArrow"
                      />
                    ) : null}
                    <ArrowForwardIosOutlinedIcon
                      style={{ color: "white" }}
                      className="ArrowRight"
                    ></ArrowForwardIosOutlinedIcon>
                  </div>
                </div>
              </div>
            ))}
      </div>
    </div>
  ) : (
    <div>
      <style>{css}</style>
      <h1 className="mt-10 ChallengeTitle font-sans font-medium text-xl">
        Challenge {challengeData?.day}
      </h1>
      <div className="flex ml-5 flex-col items-center CalenderCSSEvent">
        {calenderArrow ? (
          <DayPicker
            components={{ Row: CurrentWeekRow }}
            showOutsideDays
            disableNavigation
            mode="single"
            modifiers={modifiers}
            modifiersClassNames={{
              selected: "my-selected",
              today: "my-today",
              withDot: "with-dot",
            }}
            modifiersStyles={{
              disabled: { fontSize: "75%" },
            }}
            styles={{
              caption: {
                display: "none",
              },
            }}
          />
        ) : (
          <DayPicker
            showOutsideDays
            disableNavigation
            mode="single"
            modifiers={modifiers}
            modifiersClassNames={{
              selected: "my-selected",
              today: "my-today",
              withDot: "with-dot",
            }}
            modifiersStyles={{
              disabled: { fontSize: "75%" },
            }}
            styles={{
              caption: {
                display: "none",
              },
            }}
          />
        )}
        {calenderArrow ? (
          <ArrowBackIosNewSharpIcon
            onClick={changeArrow}
            className="CalenderDownArrow"
          ></ArrowBackIosNewSharpIcon>
        ) : (
          <ArrowBackIosNewSharpIcon
            onClick={changeArrow}
            className="CalenderUpArrow"
          ></ArrowBackIosNewSharpIcon>
        )}
      </div>
      <div className="mt-6 ml-14">
        {/* <nav aria-label="Page navigation example">
          <ul className="inline-flex -space-x-px text-base h-10">
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("allEventClick")}
                className={` ${
                  allEventClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight   border-e-0 border border-gradient-orange-red rounded-s-lg hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                All
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("comingUpEventClick")}
                className={` ${
                  comingUpEventClick
                    ? `text-white rectangleIntense`
                    : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight   border-e-0 border border-gradient-orange-red rounded-s-lg hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                Upcoming
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("SignedUpClick")}
                className={` ${
                  SignedUpClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight   border-e-0 border border-gradient-orange-red rounded-s-lg hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white w-[6.85rem]`}
              >
                Signed Up
              </a>
            </li>
          </ul>
        </nav> */}
        <nav aria-label="Page navigation example">
          <ul className="inline-flex -space-x-px text-base h-10">
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("allEventClick")}
                className={`${
                  allEventClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight border-e-0 border border-gradient-orange-red  hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                All
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("comingUpEventClick")}
                className={`${
                  comingUpEventClick
                    ? `text-white rectangleIntense`
                    : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight border-e-0 border border-gradient-orange-red hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white`}
              >
                Upcoming
              </a>
            </li>
            <li className="w-1/3">
              <a
                onClick={() => ChangeCLick("SignedUpClick")}
                className={`${
                  SignedUpClick ? `text-white rectangleIntense` : `text-red`
                } flex items-center justify-center px-4 h-10 ms-0 leading-tight border border-gradient-orange-red  hover:bg-gray-100 hover:text-white-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-red-700 dark:hover:text-white w-[6.85rem]`}
              >
                Signed Up
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="ml-4 mt-5 mr-2 mb-24">
        {allEventClick &&
          eventDataState?.map((data) => (
            <>
              <div className="flex items-center justify-between">
                <h1 className="font-sans font-medium text-2xl">
                  {format(parseISO(data?.date), "EEEE do")}
                </h1>
                <hr className="w-44 h-0.5 mx-4 bg-accent border-0 rounded md:my-10 dark:accent" />
              </div>
              <div
                onClick={() => handleDetailsEvent(data?.id)}
                className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSize"
              >
                <figure>
                  <img src={data?.image} alt="Movie" />
                </figure>
                <div className="card-body cardLeft">
                  <h2 className="card-title text-white">{data?.title}</h2>
                  <div className="card-actions mt-2">
                    <AccessTimeFilledOutlinedIcon
                      style={{ color: "white" }}
                    ></AccessTimeFilledOutlinedIcon>
                    <h2 className="text-white">
                      {data?.start_time.substring(0, 5)} -{" "}
                      {data?.end_time.substring(0, 5)}
                    </h2>
                  </div>
                  <div className="flex flex-col justify-between  ArrowLeft">
                    {userEventDataSet && userEventDataSet.includes(data?.id) ? (
                      <NotificationsIcon
                        style={{ color: "white" }}
                        className="BellArrow"
                      />
                    ) : null}
                    <ArrowForwardIosOutlinedIcon
                      style={{ color: "white" }}
                      className="ArrowRight"
                    ></ArrowForwardIosOutlinedIcon>
                  </div>
                </div>
              </div>
            </>
          ))}
        {comingUpEventClick &&
          comingUpEventData?.map((data) => (
            <>
              <div className="flex items-center justify-between">
                <h1 className="font-sans font-medium text-2xl">
                  {format(parseISO(data?.date), "EEEE do")}
                </h1>
                <hr className="w-44 h-0.5 mx-4 bg-accent border-0 rounded md:my-10 dark:accent" />
              </div>
              <div
                onClick={() => handleDetailsEvent(data?.id)}
                className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSize"
              >
                <figure>
                  <img src={data?.image} alt="Movie" />
                </figure>
                <div className="card-body cardLeft">
                  <h2 className="card-title text-white">{data?.title}</h2>
                  <div className="card-actions mt-2">
                    <AccessTimeFilledOutlinedIcon
                      style={{ color: "white" }}
                    ></AccessTimeFilledOutlinedIcon>
                    <h2 className="text-white">
                      {data?.start_time.substring(0, 5)} -{" "}
                      {data?.end_time.substring(0, 5)}
                    </h2>
                  </div>
                  <div className="flex flex-col justify-between  ArrowLeft">
                    {userEventDataSet && userEventDataSet.includes(data?.id) ? (
                      <NotificationsIcon
                        style={{ color: "white" }}
                        className="BellArrow"
                      />
                    ) : null}
                    <ArrowForwardIosOutlinedIcon
                      style={{ color: "white" }}
                      className="ArrowRight"
                    ></ArrowForwardIosOutlinedIcon>
                  </div>
                </div>
              </div>
            </>
          ))}
        {SignedUpClick &&
          eventDataState
            ?.filter((data) => userEventDataSet.includes(data?.id))
            .map((data) => (
              <>
                <div className="flex items-center justify-between">
                  <h1 className="font-sans font-medium text-2xl">
                    {format(parseISO(data?.date), "EEEE do")}
                  </h1>
                  <hr className="w-44 h-0.5 mx-4 bg-accent border-0 rounded md:my-10 dark:accent" />
                </div>
                <div
                  onClick={() => handleDetailsEvent(data?.id)}
                  className="card mt-8 mb-8 card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSize"
                >
                  <figure>
                    <img src={data?.image} alt="Movie" />
                  </figure>
                  <div className="card-body cardLeft">
                    <h2 className="card-title text-white">{data?.title}</h2>
                    <div className="card-actions mt-2">
                      <AccessTimeFilledOutlinedIcon
                        style={{ color: "white" }}
                      ></AccessTimeFilledOutlinedIcon>
                      <h2 className="text-white">
                        {data?.start_time.substring(0, 5)} -{" "}
                        {data?.end_time.substring(0, 5)}
                      </h2>
                    </div>
                    <div className="flex flex-col justify-between  ArrowLeft">
                      {userEventDataSet &&
                      userEventDataSet.includes(data?.id) ? (
                        <NotificationsIcon
                          style={{ color: "white" }}
                          className="BellArrow"
                        />
                      ) : null}
                      <ArrowForwardIosOutlinedIcon
                        style={{ color: "white" }}
                        className="ArrowRight"
                      ></ArrowForwardIosOutlinedIcon>
                    </div>
                  </div>
                </div>
              </>
            ))}
      </div>
    </div>
  );
};
export default EventPage;
{
  /*
   <div className="overlap-3">
          <div
            onClick={() => ChangeCLick("allEventClick")}
            className={`rectangleModerate ml-[3rem] ${
              allEventClick && `toggleBackground`
            }`}
          >
            <h1
              className={`${
                allEventClick ? `toggleText` : `toggleRev`
              } text-wrapper-Moderate`}
            >
              Allevent
            </h1>
          </div>
          <div
            onClick={() => ChangeCLick("allEventClick")}
            className={`${
              allEventClick ? `toggleText` : `toggleRev`
            } text-wrapper-Moderate`}
          >
            All
          </div> 
           <div
            className={`rectangleIntense ${
              comingUpEventClick && `toggleBackground`
            }`}
          />
          <div
            onClick={() => ChangeCLick("comingUpEventClick")}
            className={`${
              comingUpEventClick ? `toggleText` : `toggleRev`
            } text-wrapper-Intense`}
          >
            Upcoming
          </div>
          <div
            className={`rectangleSignedUp ${
              SignedUpClick && `toggleBackground`
            }`}
          />
          <div
            onClick={() => ChangeCLick("SignedUpClick")}
            className={`${
              SignedUpClick ? `toggleText` : `toggleRev`
            } text-wrapper-SignedUp`}
          >
            Signed Up
          </div> 
          </div>
  */
}
