import "./ProfilePage.css";
import { endOfWeek, isWithinInterval, startOfWeek } from "date-fns";
import { DayPicker, Row } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { useUserInfoQuery } from "../../features/auth/authAPI";
import { getCookies } from "../../utils/Cookies";
import { useEffect, useState } from "react";
import Madels from "./Madels/Madels";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import Badges from "./Badges/Badges";
import { useDispatch } from "react-redux";
import { LoggedInUserInfoSuccess } from "../../features/auth/authSlice";

const ProfilePage = () => {
  const accessCode = getCookies("accessToken");
  const data = {
    token: accessCode,
  };
  const { data: userData, error, isLoading } = useUserInfoQuery(data);
  const dispatch = useDispatch();
  const [userDataSet, setUserData] = useState();

  useEffect(() => {
    setUserData(userData);
    dispatch(LoggedInUserInfoSuccess(userData));
  }, [userData]);

  function CurrentWeekRow(props) {
    const isDateInCurrentWeek = (dateToCheck) => {
      const today = new Date();
      const start = startOfWeek(today);
      const end = endOfWeek(today);
      return isWithinInterval(dateToCheck, { start, end });
    };

    const isNotCurrentWeek = props.dates.every(
      (date) => !isDateInCurrentWeek(date)
    );

    if (isNotCurrentWeek) return <></>;
    return <Row {...props} />;
  }

  const [calenderArrow, setCalenderArrow] = useState(false);
  const changeArrow = () => {
    setCalenderArrow(!calenderArrow);
  };

  const [range, setRange] = useState();

  const calculateNumberOfDays = (range) => {
    if (range?.from?.toDateString() && range?.to === undefined) {
      return 1;
    }
    if (range && range.from && range.to) {
      const fromTimestamp = Date.UTC(
        range.from.getFullYear(),
        range.from.getMonth(),
        range.from.getDate()
      );
      const toTimestamp = Date.UTC(
        range.to.getFullYear(),
        range.to.getMonth(),
        range.to.getDate()
      );
      const diffDays = Math.floor(
        (toTimestamp - fromTimestamp) / (1000 * 60 * 60 * 24)
      );
      return diffDays + 1;
    }
    return 0;
  };

  const isLastInRange = (day) => {
    return range && range.to && day && day.getTime() === range.to.getTime();
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div>
      {isDesktop ? (
        <div className="mt-8 ml-[6.5rem]">
          <div>
            <h1 className="text-xl font-bold">
              Hello {userDataSet?.username} 👋
            </h1>
          </div>
          <div className="flex  gap-64 mr-8 mt-[-2rem]">
            <div className="mt-[6rem]">
              <div className="flex ml-[10rem]">
                <h1 className="text-2xl font-bold">
                  {calculateNumberOfDays(range)}
                </h1>
                <h1 className="text-xl font-bold ml-3">Days Streak</h1>
              </div>
              <DayPicker
                showOutsideDays
                disableNavigation
                mode="range"
                selected={range}
                onSelect={setRange}
                modifiers={{
                  selected: range,
                  lastInRange: isLastInRange,
                }}
                modifiersClassNames={{
                  today: "my-today",
                  lastInRange: "last-in-range",
                }}
                styles={{
                  root: {
                    fontSize: "1.5rem", // Increased font size
                  },
                  day: {
                    padding: "0.75rem", // Increased padding
                    margin: "0.5rem", // Increased margin
                  },
                  caption: {
                    display: "none",
                  },
                  months: {
                    padding: "1.5rem", // Increased padding
                  },
                }}
              />
            </div>
            <div
              className="ml-[-3rem] mt-20 h-[30rem] w-full overflow-y-auto overflow-x-hidden pr-4"
              style={{ direction: "rtl" }}
            >
              <div className="pr-4 ml-[2rem]" style={{ direction: "ltr" }}>
                <Madels />
                <Badges />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="ml-8">
          <div className="text-wrapper-44">
            Hello {userDataSet?.username} 👋
          </div>
          <div className="flex flex-col items-center mt-20">
            <h1 className="font-sans font-medium text-5xl">
              {calculateNumberOfDays(range)}
            </h1>
            <h1 className="font-sans font-medium text-2xl">Days Streak</h1>
          </div>
          <div className="flex flex-col items-center CalenderCSS">
            {calenderArrow ? (
              <DayPicker
                components={{ Row: CurrentWeekRow }}
                showOutsideDays
                disableNavigation
                mode="range"
                selected={range}
                onSelect={setRange}
                modifiers={{
                  selected: range,
                  lastInRange: isLastInRange,
                }}
                modifiersClassNames={{
                  today: "my-today",
                  lastInRange: "last-in-range",
                }}
                styles={{
                  caption: {
                    display: "none",
                  },
                }}
              />
            ) : (
              <DayPicker
                showOutsideDays
                disableNavigation
                mode="range"
                selected={range}
                onSelect={setRange}
                modifiersClassNames={{
                  today: "my-today",
                }}
                styles={{
                  caption: {
                    display: "none",
                  },
                }}
              />
            )}
            {calenderArrow ? (
              <ArrowBackIosNewSharpIcon
                onClick={changeArrow}
                className="CalenderDownArrow"
              ></ArrowBackIosNewSharpIcon>
            ) : (
              <ArrowBackIosNewSharpIcon
                onClick={changeArrow}
                className="CalenderUpArrow"
              ></ArrowBackIosNewSharpIcon>
            )}
          </div>
          <div className="ml-[-2.5rem]">
            <Madels></Madels>
            <Badges></Badges>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePage;
