import AppleIcon from "@mui/icons-material/Apple";
import FacebookIcon from "@mui/icons-material/Facebook";
import GoogleIcon from "@mui/icons-material/Google";
import lefticon from "../../assets/images/Group11246.png";
import "./CreateAccount1.css";
import { Link } from "react-router-dom";
import { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { setToLocalStorage } from "../../utils/local-storage";
import Subtract from "../../assets/images/Subtract.png";
import {
  signInWithApple,
  signInWithFacebook,
  signInWithGoogle,
  userInfo,
} from "../../features/auth/authSlice";
import { useRegisterMutation } from "../../features/auth/authAPI";
import toast, { Toaster } from "react-hot-toast";
const Createyouraccount1 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    pass: "",
    confirmpassword: "",
  });
  const [passwordMatched, setPasswordMatched] = useState(false);
  const [register, { isLoading, data, isSuccess, error: responseError }] =
    useRegisterMutation();
  const registerInitiatedRef = useRef(false);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const submitHandler = (e) => {
    console.log(formData);
    e.preventDefault();
    if (formData.pass !== formData.confirmpassword) {
      setPasswordMatched(true);
    } else {
      setToLocalStorage("step1", JSON.stringify(formData));
      navigate("/signUp2");
    }
  };
  const [userInfoAuth, setUserInfoAuth] = useState();
  const GoogleClick = () => {
    console.log("google");
    signInWithGoogle()
      .then((result) => {
        setUserInfoAuth({
          born_year: process.env.REACT_APP_year,
          username: result.user.displayName,
          gender: process.env.REACT_APP_gender,
          is_student: process.env.REACT_APP_is_student,
          country: process.env.REACT_APP_country,
          state: process.env.REACT_APP_state,
          city: process.env.REACT_APP_city,
          email: result.user.email,
          password: process.env.REACT_APP_password,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const notify = (message) => toast(message);
  const AppleClick = () => {
    signInWithApple()
      .then((result) => {
        console.log("Apple sign-in successful", result.user);
      })
      .catch((error) => {
        console.error("Apple sign-in error", error);
      });
  };
  const FacebookClick = () => {
    signInWithFacebook()
      .then((result) => {
        setUserInfoAuth({
          born_year: process.env.REACT_APP_year,
          username: result.user.displayName,
          gender: process.env.REACT_APP_gender,
          is_student: process.env.REACT_APP_is_student,
          country: process.env.REACT_APP_country,
          state: process.env.REACT_APP_state,
          city: process.env.REACT_APP_city,
          email: result.user.email,
          password: process.env.REACT_APP_password,
        });
      })
      .catch((error) => {
        console.error("Apple sign-in error", error);
      });
  };
  useEffect(() => {
    if (userInfoAuth && !registerInitiatedRef.current) {
      registerInitiatedRef.current = true;
      register(userInfoAuth)
        .unwrap()
        .then((result) => {
          console.log("Registration successful:", result);
          if (result.activation_token) {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Registration errorrrr:", error.data.email[0]);
          notify(error.data.email[0]);
        });
    }
  }, [userInfoAuth, register, navigate]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex flex-col lg:flex-row h-[120vh] overflow-hidden">
      <div
        className="hidden lg:block flex-1 bg-no-repeat bg-cover h-full w-full"
        style={{
          backgroundImage: `url(${Subtract})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex items-center justify-center h-full text-white text-3xl text-center font-noto-sans">
          <div className="transform lg:transform-none">
            <h1 className="font-bold ml-[-200px]">Welcome to the</h1>
            <h1 className="font-bold ml-[-120px]">Happy Nation Games</h1>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center bg-[#fffbf4]">
        <div className="relative bg-[#fffbf4] w-[90vw] max-w-[350px] flex flex-col items-center p-4 lg:p-0">
          {!isDesktop && (
            <div onClick={() => navigate(-1)}>
              <img
                src={lefticon}
                alt="back icon"
                className="h-8 w-8 absolute top-0 left-4 mt-[17px]"
              />
            </div>
          )}
          <div className="flex justify-center  gap-2 items-center mt-4">
            <div className="w-10 h-1 bg-[#f17430]" />
            <div className="w-10 h-1 bg-[#cecece]" />
            {!isDesktop && <div className="w-10 h-1 bg-[#cecece]" />}
            <div className="w-10 h-1 bg-[#cecece]" />
          </div>
          <p className="text-[#1d1d1d] text-2xl font-medium text-center mt-1 lg:mt-24 mb-6 lg:mb-6">
            Create your Account
          </p>
          <form
            onSubmit={submitHandler}
            className="flex flex-col items-center w-full"
          >
            <input
              className="w-11/12 border-b border-[#f17430] py-1 mb-4 lg:mb-4"
              type="email"
              id="email"
              placeholder="Email"
              required
              onChange={handleInputChange}
            />
            <input
              className="w-11/12 border-b border-[#f17430] py-1 mb-4 lg:mb-4"
              type="text"
              id="username"
              placeholder="Username"
              onChange={handleInputChange}
              required
            />
            <input
              className="w-11/12 border-b border-[#f17430] py-2 mb-4 lg:mb-4"
              type="password"
              id="pass"
              placeholder="Password"
              required
              onChange={handleInputChange}
            />
            <input
              className="w-11/12 border-b border-[#f17430] py-2 mb-4 lg:mb-4"
              type="password"
              id="confirmpassword"
              placeholder="Confirm Password"
              required
              onChange={handleInputChange}
            />
            <button className="w-80 h-14 mt-4 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg mb-8 lg:mb-16">
              Next
            </button>
          </form>
          <div className="flex items-center justify-between w-80 mb-4 lg:mb-6">
            <div className="h-px w-2/5 bg-[#f17430]" />
            <p className="text-[#1d1d1f] font-medium">or</p>
            <div className="h-px w-2/5 bg-[#f17430]" />
          </div>
          <div className="flex justify-between items-center w-full px-8 mb-4 lg:mb-8">
            <FacebookIcon
              onClick={FacebookClick}
              fontSize="medium"
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
            <GoogleIcon
              onClick={GoogleClick}
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
            <AppleIcon
              onClick={AppleClick}
              sx={{
                color: "white",
                width: 30,
                height: 30,
                backgroundColor: "#F17430",
                padding: "5px",
                borderRadius: 5,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createyouraccount1;
