import { apiSlice } from "../api/apiSlice";
export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/accounts/register/",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/accounts/login/",
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    logOut: builder.mutation({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/accounts/logout/",
        method: "POST",
        body: data.refreshToken,
        headers: {
          Authorization: `Bearer ${data.accessToken}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    userInfo: builder.query({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/accounts/user-info",
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    countryInfo: builder.query({
      query: () => ({
        url: "https://gamedemoserver.happynation.global/accounts/get-countries/",
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    stateInfo: builder.mutation({
      query: (data) => ({
        url: `https://gamedemoserver.happynation.global/accounts/get-states/${data?.country}/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    cityInfo: builder.mutation({
      query: (data) => ({
        url: `https://gamedemoserver.happynation.global/accounts/get-cities/${data?.country}/${data?.state}/`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useRegisterMutation,
  useLoginMutation,
  useUserInfoQuery,
  useCountryInfoQuery,
  useStateInfoMutation,
  useCityInfoMutation,
  useLogOutMutation,
} = authApi;
