// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container_Nav {
  background: white;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 85px;
  border-radius: 10px 10px 0px 0px;
  box-shadow: #00000085 1px 2px 20px;
}
.container {
  background: white;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  bottom: 0px;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0px 0px;
  box-shadow: #00000085 1px 2px 20px;
}
.element {
  width: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.icon {
  color: rgb(209, 202, 202);
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon:hover {
  transition: 0.2s;
  color: rgba(255, 166, 0, 0.753);
}
.iconActive{
    color: rgba(255, 166, 0, 0.753);
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Shared/Navbar/MobileNavBar.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,kCAAkC;AACpC;AACA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,WAAW;EACX,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,kCAAkC;AACpC;AACA;EACE,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,+BAA+B;AACjC;AACA;IACI,+BAA+B;AACnC","sourcesContent":[".container_Nav {\n  background: white;\n  position: fixed;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  bottom: 0px;\n  width: 100%;\n  height: 85px;\n  border-radius: 10px 10px 0px 0px;\n  box-shadow: #00000085 1px 2px 20px;\n}\n.container {\n  background: white;\n  position: fixed;\n  display: flex;\n  justify-content: space-evenly;\n  align-items: center;\n  bottom: 0px;\n  width: 100%;\n  height: 100%;\n  border-radius: 10px 10px 0px 0px;\n  box-shadow: #00000085 1px 2px 20px;\n}\n.element {\n  width: 15%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n}\n.icon {\n  color: rgb(209, 202, 202);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.icon:hover {\n  transition: 0.2s;\n  color: rgba(255, 166, 0, 0.753);\n}\n.iconActive{\n    color: rgba(255, 166, 0, 0.753);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
