import "./DailySurvey.css";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useEffect, useState } from "react";
import { getCookies } from "../../../utils/Cookies";
import phywithStar from "../../../assets/images/_ Physical challenge.png";
import menwithStar from "../../../assets/images/_ Mental challenge.png";
import soiwithStar from "../../../assets/images/_ Social challenge.png";
import uploadwithStar from "../../../assets/images/I uploaded a video on Instagram.png";
const DailySurvey = ({ data }) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f17430",
      },
    },
  });
  const accessCode = getCookies("accessToken");
  const [Physicalchecked, setPhysicalChecked] = useState(false);
  const [Mentalchecked, setMentalChecked] = useState(false);
  const [Socialchecked, setSocialChecked] = useState(false);
  const [Vediochecked, setVedioChecked] = useState(false);
  const handleChange = (clickedData) => {
    if (clickedData === "Physical") {
      setPhysicalChecked(!Physicalchecked);
    } else if (clickedData === "Mental") {
      setMentalChecked(!Mentalchecked);
    } else if (clickedData === "Social") {
      setSocialChecked(!Socialchecked);
    } else if (clickedData === "Vedio") {
      setVedioChecked(!Vediochecked);
    }
  };
  const handleSubmit = () => {
    console.log(
      "handle submit",
      Physicalchecked,
      Mentalchecked,
      Socialchecked,
      Vediochecked,
      inputUrl
    );
    fetch("https://gamedemoserver.happynation.global/surveys/create/", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessCode}`,
      },
      body: JSON.stringify({
        physical_done: Physicalchecked,
        mental_done: Mentalchecked,
        social_done: Socialchecked,
        video_link: inputUrl,
        challenge: data,
      }),
    });
  };
  const [inputUrl, setInputUrl] = useState("");
  const handleInputChange = (e) => {
    // console.log(e.target.value);
    setInputUrl(e.target.value);
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div
      className={`flex justify-center flex-col ${
        isDesktop ? `items-start ml-[7rem]` : `items-center`
      } mt-[6.25rem]`}
    >
      <div className="flex items-center">
        <h1 className="font-bold text-xl mt-6">Daily Survey</h1>
        {/* <HelpOutlineIcon
          sx={{
            fontSize: 25,
            color: "#f17430cc",
          }}
        ></HelpOutlineIcon> */}
      </div>
      <h1
        className={`${isDesktop ? `text-left` : `text-center`}   font-bold ${
          !isDesktop && `ml-[1rem]`
        } mt-8`}
      >
        To win a medal, you need to confirm here that you did the challenges. To
        win the daily prizes, <br /> you need to prove that you did the physical
        challenge by posting a video
      </h1>
      <div
        className={`mt-5 ml-3 flex ${
          isDesktop ? `flex-row` : `flex-col`
        } gap-3`}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={theme}>
                {Physicalchecked ? (
                  <TaskAltIcon
                    onClick={() => handleChange("Physical")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    onClick={() => handleChange("Physical")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                )}
              </ThemeProvider>
            }
            label={
              <h1 className="ml-2 font-bold">
                {/* <img className="w-[8rem] h-4" src={phywithStar}></img> */}
                Physical Challenge
              </h1>
            }
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={theme}>
                {Mentalchecked ? (
                  <TaskAltIcon
                    onClick={() => handleChange("Mental")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    onClick={() => handleChange("Mental")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                )}
              </ThemeProvider>
            }
            label={
              <h1 className="ml-2 font-bold">
                {/* <img className="w-[8rem] h-4" src={menwithStar}></img> */}
                Mental Challenge
              </h1>
            }
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={theme}>
                {Socialchecked ? (
                  <TaskAltIcon
                    onClick={() => handleChange("Social")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    onClick={() => handleChange("Social")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                )}
              </ThemeProvider>
            }
            label={
              <h1 className="ml-2 font-bold">
                {/* <img className="w-[8rem] h-4" src={soiwithStar}></img> */}
                Social Challenge
              </h1>
            }
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <ThemeProvider theme={theme}>
                {Vediochecked ? (
                  <TaskAltIcon
                    onClick={() => handleChange("Vedio")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                ) : (
                  <RadioButtonUncheckedIcon
                    onClick={() => handleChange("Vedio")}
                    color="primary"
                    sx={{ color: "#f17430" }}
                  />
                )}
              </ThemeProvider>
            }
            label={
              <h1 className="ml-2 font-bold">
                {/* <img className="w-[12rem] h-4" src={uploadwithStar}></img> */}
                I uploaded a video on Instagram
              </h1>
            }
          />
        </FormGroup>
      </div>
      <input
        placeholder="URL for the video"
        onChange={handleInputChange}
        className="peer inputSurvey h-full bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline-none transition-all placeholder-shown:border-b-2 border-[#f17430cc]  disabled:border-0 disabled:bg-blue-gray-50"
      />
      <button
        onClick={handleSubmit}
        className="flex submitButton mt-4 justify-center items-center  rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out"
      >
        Submit
      </button>
    </div>
  );
};
export default DailySurvey;
