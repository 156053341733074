import "./Madels.css";
import bronze from "../../../assets/images/bronze medal.png";
import goldMedel from "../../../assets/images/golden medal no color.png";
import Pie from "../../../utils/Pie";
import { getCookies } from "../../../utils/Cookies";
import { useGetMedalListQuery } from "../../../features/DailyPrizeList/DailyPrizeListAPI";
import { getFromLocalStorage } from "../../../utils/local-storage";
import { useEffect, useState } from "react";
const Madels = () => {
  const accessCode = getCookies("accessToken");
  const data = {
    token: accessCode,
  };
  const { data: userMedelData, error, isLoading } = useGetMedalListQuery(data);
  const d = JSON.parse(getFromLocalStorage("localData"));
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div className={`flex flex-col ${isDesktop? `ml-16`:`ml-12`}`}>
      <h1
        className={`font-sans font-medium font-bold text-2xl text-left  ${
          isDesktop ? `yourProgress` : `ml-[5rem]`
        }`}
      >
        Your Progress
      </h1>
      <div className="flex ml-8 divide-x-2 divide-amber-400 divide-opacity-100 mt-6">
        <div className="flex flex-col challengeday">
          <h1 className="text-center">
            Challenge
            {isDesktop ? null : <br />}
            Day
          </h1>
          <h1 className="text-center font-bold text-2xl">{d?.id}</h1>
        </div>
        <div className="flex flex-col MedalPic">
          <h1 className="text-center mt-4">Medal</h1>
          <div className="mt-0">
            {userMedelData ? (
              <Pie
                percentage={userMedelData[0]?.progression_rate}
                image={goldMedel}
              ></Pie>
            ) : (
              <Pie percentage={80} image={goldMedel}></Pie>
            )}
          </div>
        </div>
        <div className="flex flex-col ">
          <h1 className="text-center longestStreak">
            Longest <br /> Streak
          </h1>
          <h1 className="text-center font-bold text-2xl">15</h1>
        </div>
      </div>
    </div>
  );
};
export default Madels;
