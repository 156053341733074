import { apiSlice } from "../api/apiSlice";
import {
  getChallengeFailure,
  getChallengeStart,
  getChallengeSuccess,
} from "./challengeSlice";
export const challengeApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getChallenge: builder.query({
      query: (data) => ({
        url: `https://gamedemoserver.happynation.global/challenges/detail/Day ${data.day}/`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
      onQueryStarted: () => {
        getChallengeStart();
      },
      onSuccess: (data) => {
        getChallengeSuccess(data);
      },
      onError: (error) => {
        getChallengeFailure(error);
      },
    }),
  }),
});
export const { useGetChallengeQuery } = challengeApi;
