import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import MobileNavBar from "../Shared/Navbar/MobileNavBar";
import Header from "../Shared/Navbar/Header/Header";

const Main = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Header></Header> 
      <Outlet></Outlet>  
      {!isDesktop && <MobileNavBar></MobileNavBar>} 
    </>
  );
};

export default Main;
