// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backButtonlogo {
  background-color: #f9f6f2;
  border-radius: 16px;
  margin-top: 30px;
  box-shadow: 1px 2px 6px 2px #00000033;
  height: 32px;
  width: 32px;
  color: #f17430;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: #933908 !important;
    color: rgb(219, 24, 24);
  }`, "",{"version":3,"sources":["webpack://./src/Pages/ProfilePage/Badges/ViewAllBadges/ViewAllBadges.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,gBAAgB;EAChB,qCAAqC;EACrC,YAAY;EACZ,WAAW;EACX,cAAc;AAChB;AACA;IACI,oCAAoC;IACpC,uBAAuB;EACzB","sourcesContent":[".backButtonlogo {\n  background-color: #f9f6f2;\n  border-radius: 16px;\n  margin-top: 30px;\n  box-shadow: 1px 2px 6px 2px #00000033;\n  height: 32px;\n  width: 32px;\n  color: #f17430;\n}\n.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {\n    background-color: #933908 !important;\n    color: rgb(219, 24, 24);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
