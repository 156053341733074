import { useEffect, useState } from "react";
import { useGetHowItWorksQuery } from "../../features/how-it-works/HowItWorksAPI";
import { getCookies } from "../../utils/Cookies";
import "./HowItWorks.css";
import VedioPlayer from "../../utils/VedioPlayer/VedioPlayer";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
const HowItWorks = () => {
  const accessCode = getCookies("accessToken");
  const { data: HowItWorksData } = useGetHowItWorksQuery({
    token: accessCode,
  });
  const ClickHowWorks = (index) => {
    const cardElement =
      document.getElementsByClassName("overlap-groupCard")[index];
    cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState();
  const openModal = (vedioData) => {
    setPlayerData({
      CloseFunction: closeModal,
      vedioData: vedioData,
    });
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [sliceIndex, setSliceIndex] = useState(0);

  const handleSlice = (increment) => {
    const newIndex = sliceIndex + increment;
    if (
      HowItWorksData &&
      newIndex >= 0 &&
      newIndex <= HowItWorksData.length - 2
    ) {
      setSliceIndex(newIndex);
    }
  };
  useEffect(() => {
    setSliceIndex(0);
  }, [HowItWorksData]);
  return isDesktop ? (
    <div className="ml-[6.5rem]">
      <h1 className="text-2xl font-bold">How it works</h1>
      <div className="flex justify-around gap-4 mt-6 mb-6">
        <ExpandCircleDownIcon
          className="mt-[13rem] ml-[-4rem]"
          style={{
            transform: "rotate(90deg)",
            fontSize: "2.0rem",
            fill: "none",
            stroke: "rgb(246, 170, 26)",
            strokeWidth: "1.5px",
          }}
          onClick={() => handleSlice(-2)} // Slice back by 3 elements
          disabled={sliceIndex === 0}
        />
        <div className="grid grid-cols-3 gap-12 ml-[-2rem]  mb-[7.5rem] pt-8">
          {HowItWorksData?.slice(sliceIndex, sliceIndex + 3)?.map(
            (data, index) => (
              <div
                className="max-w-xs rounded-2xl overflow-hidden shadow-xl"
                style={{
                  background: "linear-gradient(to right, #EE4E36, #F6AA1A)",
                }}
              >
                <div className="px-6 py-4 ">
                  <div className="font-bold text-white text-xl mb-2">{data?.title}</div>
                  <p className="text-gray-700 text-white text-base">{data?.description}</p>
                </div>
                <img
                  onClick={() => openModal(data?.video_link)}
                  className="w-full"
                  src={`https://gamedemoserver.happynation.global${data?.image}`}
                  alt="Sunset in the mountains"
                />
              </div>
            )
          )}
          {isModalOpen && (
            <VedioPlayer
              videoSource={playerData}
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          )}
        </div>
        <ExpandCircleDownIcon
          className="mt-[13rem] ml-[-4rem]"
          style={{
            transform: "rotate(270deg)",
            fontSize: "2.0rem",
            fill: "none",
            stroke: "rgb(246, 170, 26)",
            strokeWidth: "1.5px",
          }}
          onClick={() => handleSlice(2)} // Slice forward by 3 elements
          disabled={sliceIndex >= HowItWorksData?.length - 2}
        />
      </div>
    </div>
  ) : (
    <div style={{ width: "100%" }} className="iphoneHow">
      <div className="div">
        <div className="text-wrapper">How it works</div>
        <div className="grid grid-cols-2 gap-4 px-1 pl-[1.05rem] mt-8">
          {/* {HowItWorksData?.map((data, index) => (
            <div className="overlap-groupHowItWorks" key={index}>
              <div
                onClick={() => ClickHowWorks(index)}
                className="text-wrapper-3-howItWorks mt-2"
              >
                {data?.title}
              </div>
            </div>
          ))} */}
          <div className="overlap-groupHowItWorks">
            <div
              //onClick={() => ClickHowWorks(index)}
              className="text-white text-center mt-2"
            >
              Physical challenge
            </div>
          </div>
          <div className="overlap-groupHowItWorks">
            <div
              //onClick={() => ClickHowWorks(index)}
              className="text-white text-center mt-2"
            >
              Physical challenge
            </div>
          </div>
          <div className="overlap-groupHowItWorks">
            <div
              //onClick={() => ClickHowWorks(index)}
              className="text-white  text-center  mt-2"
            >
              Social challenge
            </div>
          </div>
          <div className="overlap-groupHowItWorks">
            <div
              //onClick={() => ClickHowWorks(index)}
              className="text-white text-center mt-2"
            >
              Prizes
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 px-1 pl-4 pt-[4rem]">
          {HowItWorksData?.map((data, index) => (
            <div className="overlap-groupCard" key={index}>
              <div className="text-wrapper-2">{data?.title}</div>
              <p className="pHowitworksPara">{data?.description}</p>
              <img
                onClick={() => openModal(data?.video_link)}
                className="img"
                alt="Mask group"
                src={`https://gamedemoserver.happynation.global${data?.image}`}
              />
            </div>
          ))}
          {isModalOpen && (
            <VedioPlayer
              videoSource={playerData}
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default HowItWorks;
