// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstPage {
  background-color: #c69f5b;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
} 
.text-wrapper-3 {
  color: #1d1d1f;
  font-family: "Noto Sans", Helvetica;
  font-size: 24px;
  font-weight: 700;
  left: 77px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 657px;
  width: 229px;
}
.overlap {
  height: 35px;
  left: 92px;
  position: absolute;
  top: 44px;
  width: 205px;
}
.UpArrow{
  margin-top: -4.5rem;
  margin-left: 7rem;
}
.UpArrow1{
  margin-top: .5rem;
  margin-left: 38rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/FirstPage/FirstPage.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;AACb;AACA;EACE,cAAc;EACd,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,UAAU;EACV,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU;EACV,YAAY;AACd;AACA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".firstPage {\n  background-color: #c69f5b;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  width: 100%;\n} \n.text-wrapper-3 {\n  color: #1d1d1f;\n  font-family: \"Noto Sans\", Helvetica;\n  font-size: 24px;\n  font-weight: 700;\n  left: 77px;\n  letter-spacing: 0;\n  line-height: normal;\n  position: absolute;\n  text-align: center;\n  top: 657px;\n  width: 229px;\n}\n.overlap {\n  height: 35px;\n  left: 92px;\n  position: absolute;\n  top: 44px;\n  width: 205px;\n}\n.UpArrow{\n  margin-top: -4.5rem;\n  margin-left: 7rem;\n}\n.UpArrow1{\n  margin-top: .5rem;\n  margin-left: 38rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
