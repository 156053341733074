import "./Badges.css";
import diamond from "../../../assets/images/Diamond@2x.png";
import Gratitude1 from "../../../assets/images/Gratitude 1@2x.png";
import Gratitude2 from "../../../assets/images/Gratitude 2@2x.png";
import Gratitude3 from "../../../assets/images/Gratitude 3@2x.png";
import Kindness1 from "../../../assets/images/Kindness 1@2x.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import Scene from "./Scene/Scene";
import React from "react";
import { useGetBadgesListQuery } from "../../../features/DailyPrizeList/DailyPrizeListAPI";
import { getCookies } from "../../../utils/Cookies";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import IosShareIcon from "@mui/icons-material/IosShare";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
const Badges = () => {
  const [open, setOpen] = useState(false);
  const [modelBadge, setModelBadge] = useState();
  const OpenModal = (data) => {
    console.log(data);
    setModelBadge(data);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const accessCode = getCookies("accessToken");
  const data = {
    token: accessCode,
  };
  const {
    data: userBadgesData,
    error,
    isLoading,
  } = useGetBadgesListQuery(data);
  const [firstPageBadgeData, setFirstPageBadgeData] = useState();
  //console.log(userBadgesData);
  const navigate = useNavigate();
  const clickViewAll = () => {
    //console.log("view all");
    navigate("/View-All-Badges");
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <>
      <div
        className={`rounded-lg ${
          isDesktop ? null : "divide-y-2 divide-amber-500"
        } mt-3 ml-12 flex flex-col gap-8`}
      >
        <div className="flex justify-between  allImages">
          <img
            width={100}
            src={diamond}
            onClick={() => OpenModal("model1")}
          ></img>
          <div className="mt-5 ml-2 ">
            <h1>You have Completed 60% of daily Challenge</h1>
            <button className="AchivementButton h-10 mt-2 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-sans font-medium">
              <div className="flex ml-5">
                <IosShareIcon></IosShareIcon> <h1>Share Achivement</h1>
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-between  allImages">
          <img
            width={100}
            src={Gratitude1}
            onClick={() => OpenModal("model2")}
          ></img>
          <div className="mt-5 ml-2 ">
            <h1>You have Completed 40% of daily Challenge</h1>
            <button className="AchivementButton h-10 mt-2 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-sans font-medium">
              <div className="flex ml-5">
                <IosShareIcon></IosShareIcon> <h1>Share Achivement</h1>
              </div>
            </button>
          </div>
        </div>
        <div className="flex justify-between  allImages">
          <img
            width={100}
            src={Gratitude3}
            onClick={() => OpenModal("model3")}
          ></img>
          <div className="mt-5 ml-2 ">
            <h1>You have Completed 40% of daily Challenge</h1>
            <button className="AchivementButton h-10 mt-2 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-sans font-medium">
              <div className="flex ml-5">
                <IosShareIcon></IosShareIcon> <h1>Share Achivement</h1>
              </div>
            </button>
          </div>
        </div>
      </div>
      {isDesktop ? null : (
        <hr className="h-px ml-[3rem] my-8 bg-gray-200 border-0 w-80 bg-red-700" />
      )}

      {isDesktop ? null : (
        <div className="flex viewAll  justify-between" onClick={clickViewAll}>
          <h1 className="font-sans ml-[3rem] font-medium text-large font-bold">
            View all
          </h1>
          <ArrowBackIosNewSharpIcon
            fontSize="small"
            className="viewAllArrow"
          ></ArrowBackIosNewSharpIcon>
        </div>
      )}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={`${isDesktop ? `modelstyle` : `modelstyleMobile`}`}>
          <IconButton
            onClick={() => handleClose()}
            aria-label="close"
            style={{ marginTop: "5rem", color: "white" }}
          >
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
          <React.Suspense>
            <Scene data={modelBadge}></Scene>
          </React.Suspense>
        </div>
      </Modal>
    </>
  );
};
export default Badges;
{
  /* <div className="rectangleBigBadges">
<div className="rectangle-4">
  <h1 className="text-wrapper-Badges">Badges</h1>
  <div className="ellipseSmallBadges">
    <img
      className="vector-question"
      alt="Vector"
      src="https://c.animaapp.com/wKmtxmGn/img/vector-3.svg"
    />
  </div>
</div>
<div className="grid grid-cols-2 gap-4 px-1 pl-3 pt-14">
  {userBadgesData?.map(() => (
    <div
      onClick={() => OpenModal("diamond")}
      className="flex flex-col items-center justify-center"
    >
      <img src={diamond}></img>
      <div className="flex flex-col rotate-60">
        <KeyboardArrowUpIcon className="arrowColor"></KeyboardArrowUpIcon>
        <KeyboardArrowDownIcon className="arrowColor"></KeyboardArrowDownIcon>
      </div>
    </div>
  ))} */
}

{
  /* <div className="flex flex-col items-center justify-center">
    <img src={Gratitude1}></img>
    <div className="flex flex-col rotate-60">
      <KeyboardArrowUpIcon className="arrowColor"></KeyboardArrowUpIcon>
      <KeyboardArrowDownIcon className="arrowColor"></KeyboardArrowDownIcon>
    </div>
  </div>
  <div className="flex flex-col items-center justify-center">
    <img src={Gratitude2}></img>
    <div className="flex flex-col rotate-60">
      <KeyboardArrowUpIcon className="arrowColor"></KeyboardArrowUpIcon>
      <KeyboardArrowDownIcon className="arrowColor"></KeyboardArrowDownIcon>
    </div>
  </div>
  <div className="flex flex-col items-center justify-center">
    <img src={Gratitude3}></img>
    <div className="flex flex-col rotate-60">
      <KeyboardArrowUpIcon className="arrowColor"></KeyboardArrowUpIcon>
      <KeyboardArrowDownIcon className="arrowColor"></KeyboardArrowDownIcon>
    </div>
  </div> */
}
//   <h1 className="text-wrapper-SeeAll">See All</h1>
//   <Modal
//     open={open}
//     aria-labelledby="modal-modal-title"
//     aria-describedby="modal-modal-description"
//   >
//     <div className="modelstyle">
//       <React.Suspense>
//         <Scene></Scene>
//       </React.Suspense>
//     </div>
//   </Modal>
// </div>
// </div>
