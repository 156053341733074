import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { useNavigate } from "react-router-dom";
const ChangePassword = () => {
  const navigate = useNavigate();
  return (
    <div className="mainDiv">
      <div>
        <ExpandCircleDownIcon
          onClick={() => navigate(-1)}
          style={{
            transform: "rotate(90deg)",
            fontSize: "2.0rem",
            fill: "none",
            stroke: "rgb(246, 170, 26)",
            strokeWidth: "1.5px",
          }}
          className="mt-10 ml-5"
        ></ExpandCircleDownIcon>
        <h1 className="mt-10 text-xl font-bold ml-5">Change Password</h1>
        <form>
          <div className="flex flex-col  ml-5 changeProfileotherinfo">
            <input
              className="ChangeProfileInfo"
              type="email"
              id="email"
              placeholder="Current Password"
              required
            />
            <input
              className="ChangeProfileInfo"
              type="text"
              id="username"
              placeholder="New Password"
              required
            />
            <input
              className="ChangeProfileInfo"
              type="password"
              id="pass"
              placeholder="Confirm Password"
              required
            />
          </div>
          <div style={{ paddingLeft: "30px", marginTop: "5rem" }}>
            <button className="flex justify-center items-center w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default ChangePassword;
