import React, { useEffect, useState } from "react";
import "./FirstPage.css";
import DailyInspiration from "./DailyInspiration/DailyInspiration";
import MentalChallenge from "./TodaysChallenge/MentalChallenge/MentalChallenge";
import SocialChallenge from "./TodaysChallenge/SocialChallenge/SocialChallenge";
import { getCookies } from "../../utils/Cookies";
import { useGetChallengeQuery } from "../../features/challenge/challengeAPI";
import DailySurvey from "./DailySurvey/DailySurvey";
import DailyPrizes from "./DailyPrizes/DailyPrizes";
import BodyChallenge from "./TodaysChallenge/BodyChallenge/BodyChallenge";
import { useGetDailyPrizeListQuery } from "../../features/DailyPrizeList/DailyPrizeListAPI";
import { useDispatch } from "react-redux";
import { getChallengeSuccess } from "../../features/challenge/challengeSlice";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  getFromLocalStorage,
  setToLocalStorage,
} from "../../utils/local-storage";

const FirstPage = () => {
  const dispatch = useDispatch();
  const accessCode = getCookies("accessToken");
  const [dayCount, setDayCount] = useState(1);
  const leftClick = () => {
    if (dayCount > 1) {
      setDayCount(dayCount - 1);
    }
  };
  const rightClick = () => {
    if (dayCount < 31) {
      setDayCount(dayCount + 1);
    }
  };
  const { data, error, isLoading, isError } = useGetChallengeQuery({
    day: dayCount,
    token: accessCode,
  });
  const {
    data: DailyPrizesListdata,
    error: DailyPrizesListError,
    isLoading: DailyPrizesListLoading,
  } = useGetDailyPrizeListQuery({
    token: accessCode,
  });
  const [DailyInspirationData, setDailyInspirationData] = useState();
  const [physicalChallengeData, setPhysicalChallengeData] = useState();
  const [mentalData, setMentalData] = useState();
  const [socialData, setSocialData] = useState();
  const [dailyPrize, setDailyPrize] = useState();
  const [dailySurveyData, setDailySurveyData] = useState();
  // const { data: storedData } = JSON.parse(getFromLocalStorage("localData"));
  // const [getlocalData, setLocalData] = useState();
  // useEffect(() => {
  //   setLocalData(storedData);
  //   if (getlocalData) {
  //     console.log(getlocalData);
  //   }
  // }, [storedData]);
  useEffect(() => {
    setDailyInspirationData({
      author_name: data?.author_name,
      quote: data?.quote,
      vedio_link: data?.video_link,
    });
    setPhysicalChallengeData({
      intense_physical: data?.intense_physical,
      moderate_physical: data?.moderate_physical,
    });
    setMentalData(data?.mental);
    setSocialData(data?.social);
    setDailySurveyData(data?.id);
    setToLocalStorage("localData", JSON.stringify(data));
    setDailyPrize(DailyPrizesListdata);
  }, [data, DailyPrizesListdata]);

  useEffect(() => {
    if (isError) {
      console.log("Error");
    }
    if (data) {
      dispatch(getChallengeSuccess(data));
    }
  }, [data, isError, dispatch]);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex gap-4 items-center ${
          isDesktop
            ? "ml-[40rem]"
            : isTablet
            ? "ml-[28rem]"
            : "ml-[6rem] mt-[-4.75rem]"
        }`}
      >
        <ArrowBackIosNewIcon onClick={leftClick} />
        <h1 className="text-xl ">Challenge {data?.day}</h1>
        <ArrowForwardIosIcon onClick={rightClick} />
      </div>
      <DailyInspiration data={DailyInspirationData} />
      <div
        className={`flex  flex-col gap-4 ${
          isDesktop ? "ml-[6rem]" : "ml-[1.25rem] items-center mr-4"
        } mt-16`}
      >
        <h1 className="text-2xl text-left font-bold">Today’s challenge</h1>
        <div className={`flex flex-col gap-12 md:flex-row mt-12 justify-center lg:flex-row ${isDesktop&&`ml-[]`}`}>
          <BodyChallenge data={physicalChallengeData} />
          <MentalChallenge data={mentalData} />
          <SocialChallenge data={socialData} />
        </div>
      </div>
      <div className={`${!isDesktop && `mr-4`}`}>
        <DailySurvey data={dailySurveyData} />
        <DailyPrizes data={dailyPrize} />
      </div>
    </div>
  );
};

export default FirstPage;
