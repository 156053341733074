// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-6 {
    height: 101px;
    left: 19px;
    position: absolute;
    top: 999px;
    width: 378px;
  }
  .overlap-6 {
    height: 101px;
    position: relative;
    width: 371px;
  }
  .rectangle-3 {
    background: linear-gradient(180deg, rgb(234, 58, 36) 0%, rgb(246, 170, 26) 100%);
    border-radius: 8px;
    box-shadow: 4px 6px 8px 2px #00000033;
    height: 100px;
    left: 1px;
    position: absolute;
    top: 0;
    width: 353px;
  }
  .mask-group-3 {
    height: 101px;
    left: 0;
    position: absolute;
    top: 0;
    width: 371px;
  }
  .text-wrapper-15 {
    color: #fffbf4;
    font-family: "Noto Sans", Helvetica;
    font-size: 16px;
    font-weight: 400;
    left: 156px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 50px;
    width: 176px;
  }
  .text-wrapper-16 {
    color: #fffbf4;
    font-family: "Noto Sans", Helvetica;
    font-size: 20px;
    font-weight: 500;
    left: 156px;
    letter-spacing: 0;
    line-height: normal;
    position: absolute;
    top: 24px;
    width: 176px;
  }
  .vector-9 {
    height: 15px;
    left: 324px;
    position: absolute;
    top: 44px;
    width: 8px;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/FirstPage/TodaysChallenge/SocialChallenge/SocialChallenge.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,UAAU;IACV,YAAY;EACd;EACA;IACE,aAAa;IACb,kBAAkB;IAClB,YAAY;EACd;EACA;IACE,gFAAgF;IAChF,kBAAkB;IAClB,qCAAqC;IACrC,aAAa;IACb,SAAS;IACT,kBAAkB;IAClB,MAAM;IACN,YAAY;EACd;EACA;IACE,aAAa;IACb,OAAO;IACP,kBAAkB;IAClB,MAAM;IACN,YAAY;EACd;EACA;IACE,cAAc;IACd,mCAAmC;IACnC,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,YAAY;EACd;EACA;IACE,cAAc;IACd,mCAAmC;IACnC,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,SAAS;IACT,YAAY;EACd;EACA;IACE,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,UAAU;EACZ","sourcesContent":[".group-6 {\n    height: 101px;\n    left: 19px;\n    position: absolute;\n    top: 999px;\n    width: 378px;\n  }\n  .overlap-6 {\n    height: 101px;\n    position: relative;\n    width: 371px;\n  }\n  .rectangle-3 {\n    background: linear-gradient(180deg, rgb(234, 58, 36) 0%, rgb(246, 170, 26) 100%);\n    border-radius: 8px;\n    box-shadow: 4px 6px 8px 2px #00000033;\n    height: 100px;\n    left: 1px;\n    position: absolute;\n    top: 0;\n    width: 353px;\n  }\n  .mask-group-3 {\n    height: 101px;\n    left: 0;\n    position: absolute;\n    top: 0;\n    width: 371px;\n  }\n  .text-wrapper-15 {\n    color: #fffbf4;\n    font-family: \"Noto Sans\", Helvetica;\n    font-size: 16px;\n    font-weight: 400;\n    left: 156px;\n    letter-spacing: 0;\n    line-height: normal;\n    position: absolute;\n    top: 50px;\n    width: 176px;\n  }\n  .text-wrapper-16 {\n    color: #fffbf4;\n    font-family: \"Noto Sans\", Helvetica;\n    font-size: 20px;\n    font-weight: 500;\n    left: 156px;\n    letter-spacing: 0;\n    line-height: normal;\n    position: absolute;\n    top: 24px;\n    width: 176px;\n  }\n  .vector-9 {\n    height: 15px;\n    left: 324px;\n    position: absolute;\n    top: 44px;\n    width: 8px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
