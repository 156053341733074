import { useEffect, useState } from "react";
import "./DailyInspiration.css";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import VedioPlayer from "../../../utils/VedioPlayer/VedioPlayer";
import quote from "../../../assets/images/quote icon 82x68.png"
import vedioIcon from "../../../assets/images/video icon 82x84.png"
const DailyInspiration = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState();
  const openModal = (vedioData) => {
    setPlayerData({
      CloseFunction: closeModal,
      vedioData: vedioData,
    });
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return isDesktop ? (
    <div className="flex flex-col min-[320px]:items-center max-[639px]:items-center sm:items-center md:items-start md:ml-24 lg:ml-24 lg:items-start mt-12 mb-3">
      <h1 className="text-2xl mt-6 font-bold">Daily Inspiration</h1>
      <div className="flex gap-2 md:flex-row lg:flex-row mt-8 flex-col">
        <div className="flex gap-2 md:flex-row lg:flex-row flex-col">
          <div className="relative bg-success min-w-[18rem] min-h-[11rem] max-w-[44rem] max-h-[36rem] md:w-[44rem] md:h-[13rem] lg:w-[44rem] lg:h-[13rem] rounded-lg mt-4 shadow-lg">
            <img
              className="w-12 ml-4 quoteImage"
              alt="Group"
              src={quote}
            />
            <h1 className="w-[35rem] h-full text-left flex  items-center text-xl mt-[-2.5rem] ml-[2rem] mr-[4rem] ml-2">
              {data?.quote}
            </h1>
            <h1
              className="font-bold 
            text-2xl  text-right  
               mt-[-2.75rem] mr-12"
            >
              {data?.author_name}
            </h1>
          </div>
          <div
            className="ml-4 relative bg-success min-w-[18rem] min-h-[11rem] max-w-[38rem] max-h-[36rem] md:w-[38rem] md:h-[13rem] lg:w-[33rem] lg:h-[13rem] rounded-lg mt-4 shadow-lg"
            style={{
              backgroundImage:
                'url("https://c.animaapp.com/QJzwa7Iu/img/image-3@2x.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <img
              className="absolute ml-4 w-16 chinamaPlay"
              alt="Group"
              src={vedioIcon}
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <PlayCircleFilledIcon
                onClick={() => openModal(data?.vedio_link)}
                sx={{
                  fontSize: 64,
                  color: "#f17430cc",
                }}
              />
              {isModalOpen && (
                <VedioPlayer
                  videoSource={playerData}
                  isOpen={isModalOpen}
                  onClose={closeModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="flex flex-col min-[320px]:items-center max-[639px]:items-center sm:items-center md:items-start md:ml-24 lg:ml-24 lg:items-start mt-12 mb-3">
      <h1 className="text-2xl  font-bold">Daily Inspiration</h1>
      <div className="flex gap-4 md:flex-row lg:flex-row flex-col mt-6">
        <div className="flex gap-2 md:flex-row lg:flex-row flex-col">
          {/* <div className="relative bg-success min-w-[18rem] min-h-[11rem] max-w-[22rem] max-h-[36rem] md:w-[38rem] md:h-[13rem] lg:w-[38rem] lg:h-[13rem] rounded-lg mt-4 shadow">
            <img
              className="w-8 quoteImage"
              alt="Group"
              src="https://c.animaapp.com/QJzwa7Iu/img/group-11167@2x.png"
            />
            <span className="w-full h-full flex justify-center items-center text-xl mt-5 ml-2">
              {data?.quote}
            </span>
            <h1 className="font-bold text-lg mt-8 ml-2">{data?.author_name}</h1>
          </div> */}
          <div className="relative bg-success min-w-[18rem] min-h-[11rem] max-w-[22rem] max-h-[36rem] md:w-[38rem] md:h-[13rem] lg:w-[38rem] lg:h-[13rem] rounded-lg mt-4 shadow-lg">
            <img
              className="w-8 quoteImage"
              alt="Group"
              src="https://c.animaapp.com/QJzwa7Iu/img/group-11167@2x.png"
            />
            <div className="w-full h-full flex justify-center items-center p-4">
              <span className="text-xl overflow-hidden overflow-ellipsis break-words">
                {data?.quote}
              </span>
            </div>
            <h1 className="font-bold text-lg mt-2 ml-[1.25rem] mb-[2rem]">
              {data?.author_name}
            </h1>
          </div>

          <div
            className="relative bg-success min-w-[18rem] min-h-[11rem] max-w-[22rem] max-h-[36rem] md:w-[38rem] md:h-[13rem] lg:w-[38rem] lg:h-[13rem] rounded-lg mt-12 shadow"
            style={{
              backgroundImage:
                'url("https://c.animaapp.com/QJzwa7Iu/img/image-3@2x.png")',
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            <img
              className="absolute right-0 w-12 chinamaPlay"
              alt="Group"
              src="https://c.animaapp.com/QJzwa7Iu/img/group-11168@2x.png"
            />
            <div className="absolute inset-0 flex items-center justify-center">
              <PlayCircleFilledIcon
                onClick={() => openModal(data?.vedio_link)}
                sx={{
                  fontSize: 65,
                  color: "#f17430cc",
                }}
              />
              {isModalOpen && (
                <VedioPlayer
                  videoSource={playerData}
                  isOpen={isModalOpen}
                  onClose={closeModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DailyInspiration;
{
  /* 
    <div className="relative bg-success min-[320px]:w-58 min-[320px]:h-36 max-[639px]:w-58 max-[639px]:h-36 md:w-[30rem] md:h-[13rem] lg:w-[30rem] lg:h-[13rem]  rounded-lg mt-4 shadow">
          <img
            className="w-8 quoteImage"
            alt="Group"
            src="https://c.animaapp.com/QJzwa7Iu/img/group-11167@2x.png"
          />
          <span class="w-full h-full flex justify-center items-center quotePosition font-black">
            {data?.quote}
          </span>
          <h1 className="font-bold authorPosition">{data?.author_name}</h1>
        </div>
   <div
          className="relative bg-success boxSize mt-8 rounded-lg mt-4 shadow"
          style={{
            backgroundImage:
              'url("https://c.animaapp.com/QJzwa7Iu/img/image-3@2x.png")',
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <img
            className="absolute  right-0  w-12 chinamaPlay"
            alt="Group"
            src="https://c.animaapp.com/QJzwa7Iu/img/group-11168@2x.png"
          />
          <div className="absolute inset-0 flex items-center justify-center">
            <PlayCircleFilledIcon
              sx={{
                fontSize: 40,
                color: "#f17430cc",
              }}
            />
          </div>
            </div> */
}
