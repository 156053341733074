// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlap {
  height: 35px;
  left: 120px;
  position: absolute;
  top: 44px;
  width: 205px;
}
.vector-1 {
  height: 15px;
  left: 12px;
  position: absolute;
  top: 12px;
  width: 8px;
}
.vector-2 {
  height: 15px;
  left: 182px;
  position: absolute;
  top: 12px;
  width: 8px;
}
.headerClass {
  background-color: #fffbf4;
}
.text-wrapper-2 {
  color: #1d1d1f;
  font-family: "Noto Sans", Helvetica;
  font-size: 16px;
  font-weight: 500;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 10px;
  width: 205px;
}
.personlogo {
  background-color: #f9f6f2;
  margin-top: 30px;
  color: #f17430;
  border-radius: 70%; /* Makes it circular */
  height: 32px;
  width: 32px;
  display: flex; /* Centers the icon vertically and horizontally */
  justify-content: center; /* Centers horizontally */
  align-items: center; 
  border: 2px solid #f17430;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/Shared/Navbar/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;AACA;EACE,YAAY;EACZ,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;EACE,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,SAAS;EACT,UAAU;AACZ;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,mCAAmC;EACnC,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,OAAO;EACP,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,gBAAgB;EAGhB,cAAc;EACd,kBAAkB,EAAE,sBAAsB;EAC1C,YAAY;EACZ,WAAW;EACX,aAAa,EAAE,iDAAiD;EAChE,uBAAuB,EAAE,yBAAyB;EAClD,mBAAmB;EACnB,yBAAyB;AAC3B","sourcesContent":[".overlap {\n  height: 35px;\n  left: 120px;\n  position: absolute;\n  top: 44px;\n  width: 205px;\n}\n.vector-1 {\n  height: 15px;\n  left: 12px;\n  position: absolute;\n  top: 12px;\n  width: 8px;\n}\n.vector-2 {\n  height: 15px;\n  left: 182px;\n  position: absolute;\n  top: 12px;\n  width: 8px;\n}\n.headerClass {\n  background-color: #fffbf4;\n}\n.text-wrapper-2 {\n  color: #1d1d1f;\n  font-family: \"Noto Sans\", Helvetica;\n  font-size: 16px;\n  font-weight: 500;\n  height: 35px;\n  left: 0;\n  letter-spacing: 0;\n  line-height: normal;\n  position: absolute;\n  text-align: center;\n  top: 10px;\n  width: 205px;\n}\n.personlogo {\n  background-color: #f9f6f2;\n  margin-top: 30px;\n  height: 32px;\n  width: 32px;\n  color: #f17430;\n  border-radius: 70%; /* Makes it circular */\n  height: 32px;\n  width: 32px;\n  display: flex; /* Centers the icon vertically and horizontally */\n  justify-content: center; /* Centers horizontally */\n  align-items: center; \n  border: 2px solid #f17430;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
