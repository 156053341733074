import { useLocation, useNavigate } from "react-router-dom";
import "./DailyPrizeDeatails.css";
import playStore from "../../../../assets/images/playStore.png";
import appStore from "../../../../assets/images/appStore.png";

const DailyPrizeDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { DailyPrizesData } = location.state;
  return (
    <div style={{ width: "100%" }} className="iphonePrize">
      <div className="div">
        <div className="overlapPrize">
          <img
            className="subtractPrize"
            alt="Subtract"
            src="https://c.animaapp.com/NjN8xSjd/img/subtract-2.svg"
          />
          <div  className="ellipsePrize" />
          <img
            className="polygonPrize"
            alt="Polygon"
            src="https://c.animaapp.com/NjN8xSjd/img/polygon-6.svg"
          />
          <div onClick={() => navigate(-1)} className="ellipse_2">
            <img
              className="vector"
              alt="Vector"
              src="https://c.animaapp.com/NjN8xSjd/img/vector-72.svg"
            />
          </div>
          <img className="group" alt="Group" src={DailyPrizesData?.logo} />
        </div>
        <div className="text_wrapper">{DailyPrizesData?.company_name}</div>
        <p className="pPrize">{DailyPrizesData?.description}</p>
        <a href={DailyPrizesData?.twitter_link} target="_blank">
          <img
            className="imageTwitterPrize"
            alt="Image"
            src="https://c.animaapp.com/apIjDicq/img/image-11@2x.png"
          />{" "}
        </a>
        <a href={DailyPrizesData?.ig_link} target="_blank">
          <img
            className="img"
            alt="Image"
            src="https://c.animaapp.com/NjN8xSjd/img/image-12@2x.png"
          />
        </a>
        <a href={DailyPrizesData?.tiktok_link} target="_blank">
          <img
            className="image_2"
            alt="Image"
            src="https://c.animaapp.com/NjN8xSjd/img/image-13@2x.png"
          />
        </a>
        <div className="overlap_group">
          <div className="text_wrapper_2">{DailyPrizesData?.content}</div>
          <div className="text_wrapper_3">{DailyPrizesData?.prize_name}</div>
        </div>
        <div className="overlap_group_wrapper">
          <div className="overlap_group_2">
            <img
              className="group_2"
              alt="Group"
              src="https://c.animaapp.com/NjN8xSjd/img/group-11178@2x.png"
            />
            <div className="ellipse_3" />
          </div>
        </div>
        <div className="layer_wrapper">
          <img
            className="layer"
            alt="Layer"
            src="https://c.animaapp.com/NjN8xSjd/img/layer-1.svg"
          />
        </div>
        <div className="playStore flex w-40">
          <img src={playStore}></img>
          <img src={appStore}></img>
        </div>
      </div>
    </div>
  );
};
export default DailyPrizeDetails;
