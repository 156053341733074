import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import "./ChangeProfile.css";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { useEffect, useState } from "react";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { userInfo } from "../../../features/auth/authSlice";
import {
  useCityInfoMutation,
  useCountryInfoQuery,
  useStateInfoMutation,
} from "../../../features/auth/authAPI";
const ChangeProfile = () => {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f17430",
      },
    },
  });
  const navigate = useNavigate();
  const handleChangePassword = () => {
    navigate("/ChangePassword");
  };
  const userInfo1 = useSelector(userInfo);
  const [formData, setFormData] = useState({
    email: userInfo1?.email || "",
    username: userInfo1?.username || "",
    country: userInfo1?.country || "",
    state: userInfo1?.state || "",
    city: userInfo1?.city || "",
    university_name: userInfo1?.university_name || "",
    gender: userInfo1?.gender || "",
    year: userInfo1?.born_year || "",
  });
  const yearsArray = [];
  for (let i = 1950; i <= 2010; i++) {
    yearsArray.push(i.toString());
  }
  const [searchCountryResults, setSearchCountryResults] = useState([]);
  const [searchStateResults, setSearchStateResults] = useState([]);
  const [searchCityResults, setSearchCityResults] = useState([]);
  const { data: CountryData } = useCountryInfoQuery();
  const [stateInfo, { isLoading, data: stateData, isSuccess, error }] =
    useStateInfoMutation();
  const [
    cityInfo,
    {
      isLoading: isCityLoading,
      data: cityData,
      isSuccess: isCitySuccess,
      error: CityError,
    },
  ] = useCityInfoMutation();
  const [dropDownCountry, setDropDownCountry] = useState({
    name: userInfo1?.country,
  });
  const [dropDownState, setDropDownState] = useState({
    name: userInfo1?.state,
  });
  const [dropDownCity, setDropDownCity] = useState({ name: userInfo1?.city });
  const handleSelectCountry = (contryValueSelected) => {
    setDropDownCountry(contryValueSelected);
    stateInfo({ country: contryValueSelected?.name });
    setSearchCountryResults([]);
  };
  const handleSelectState = (stateValueSelected) => {
    setDropDownState(stateValueSelected);
    cityInfo({
      country: dropDownCountry?.name,
      state: stateValueSelected?.name,
    });
    setSearchStateResults([]);
  };
  const handleSelectCity = (cityValueSelected) => {
    setDropDownCity(cityValueSelected);
    setSearchCityResults([]);
  };
  const [stateCountryData, setStateCountryData] = useState();
  const [stateStateData, setStateData] = useState();
  const [stateCityData, setStateCityData] = useState();
  useEffect(() => {
    if (CountryData?.length > 0) {
      setStateCountryData(CountryData);
    }
    if (stateData?.length > 0) {
      setStateData(stateData);
    }
    if (cityData?.length > 0) {
      setStateCityData(cityData);
    }
  }, [CountryData, stateData, cityData]);
  const [isStudent, setIsStudent] = useState(userInfo1?.is_student || false);
  const isStudentChange = () => {
    setIsStudent(!isStudent);
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "country") {
      const filteredResults = stateCountryData.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setSearchCountryResults(filteredResults);
        setDropDownCountry({ country: value });
      }
    }
    if (id === "state") {
      const filteredResults = stateStateData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownState({ state: value });
        setSearchStateResults(filteredResults);
      }
    }
    if (id === "city") {
      const filteredResults = stateCityData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownCity({ state: value });
        setSearchCityResults(filteredResults);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const [finalUpdateData, setFinalUpdateData] = useState();
  useEffect(() => {
    setFinalUpdateData(formData);
  }, [formData]);
  const [functionCall, setFunctionCall] = useState(false);
  const submitHandler = (e) => {
    e.preventDefault();
    const submittedValueCountry = document.getElementById("country").value;
    const submittedValueState = document.getElementById("state").value;
    const submittedValueCity = document.getElementById("city").value;
    setFormData({
      ...formData,
      country: submittedValueCountry,
      state: submittedValueState,
      city: submittedValueCity,
    });
    setFunctionCall(true);
  };
  useEffect(() => {
    if (functionCall === true) {
      console.log("finalUpdateData:", finalUpdateData);
      setFunctionCall(false);
    }
  }, [finalUpdateData, functionCall]);
  return (
    <div className="mainDiv">
      <div>
        <ExpandCircleDownIcon
          onClick={() => navigate(-1)}
          style={{
            transform: "rotate(90deg)",
            fontSize: "2.0rem",
            fill: "none",
            stroke: "rgb(246, 170, 26)",
            strokeWidth: "1.5px",
          }}
          className="mt-10 ml-5"
        ></ExpandCircleDownIcon>
        <h1 className="mt-10 text-xl font-bold ml-5">Profile</h1>
        <div>
          <form onSubmit={submitHandler}>
            <div className="flex flex-col gap-10 ml-5 mt-5">
              <input
                className="ChangeProfileInfo"
                type="email"
                id="email"
                placeholder="Your Email"
                required
                value={formData?.email}
                onChange={handleInputChange}
              />
              <input
                className="ChangeProfileInfo"
                type="text"
                id="username"
                placeholder="Your Name"
                value={formData?.username}
                required
                onChange={handleInputChange}
              />
              <div
                className="flex justify-between w-80 ml-2 "
                onClick={handleChangePassword}
              >
                <h1 className="font-large mr-4">Change your password</h1>
                <ArrowBackIosNewSharpIcon
                  className="LogoutlogoChangeProfile"
                  fontSize="small"
                />
              </div>
            </div>
            <div className="flex flex-col  ml-5 changeProfileotherinfo">
              <select
                id="year"
                required
                value={formData?.year}
                onChange={handleInputChange}
                className="inputbornProfileChange"
              >
                <option value="" disabled selected>
                  Year you were born
                </option>
                {yearsArray.map((y) => (
                  <option value={y} key={y}>
                    {y}
                  </option>
                ))}
              </select>
              <select
                required
                value={formData?.gender}
                id="gender"
                onChange={handleInputChange}
                className="inputgenderProfileChange  "
              >
                <option
                  className="optionsize"
                  value=""
                  disabled
                  selected
                  hidden
                >
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
                <option value="Prefer not to share">Prefer not to share</option>
              </select>
              <div className="ChangeProfileCheckbox mt-32 ml-2">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <ThemeProvider theme={theme}>
                        {isStudent ? (
                          <TaskAltIcon
                            onClick={isStudentChange}
                            color="primary"
                            sx={{ color: "#f17430" }}
                          />
                        ) : (
                          <RadioButtonUncheckedIcon
                            onClick={isStudentChange}
                            color="primary"
                            sx={{ color: "#f17430" }}
                          />
                        )}
                      </ThemeProvider>
                    }
                    label={<h1 className="ml-2">I'm a student</h1>}
                  />
                </FormGroup>
              </div>
              {isStudent && (
                <input
                  className="ChangeProfileInfo mt-28"
                  type="text"
                  id="university_name"
                  placeholder="University Name"
                  required
                  value={formData?.university_name}
                />
              )}
            </div>
            <div
              className={`flex flex-col  ml-5  ${
                isStudent
                  ? "changeProfileAddress"
                  : "changeProfileAddressNotstudent"
              }`}
            >
              <input
                className="countryProfileChange"
                id="country"
                onChange={handleInputChange}
                placeholder="Country"
                value={dropDownCountry?.name}
              ></input>
              {searchCountryResults.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 rounded optionWidth dropDownCountry py-1 shadow-lg max-h-40 overflow-y-auto">
                  {searchCountryResults.map((result, index) => (
                    <li
                      key={index}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleSelectCountry(result)}
                    >
                      {result?.name}
                    </li>
                  ))}
                </ul>
              )}
              <input
                value={dropDownState?.name}
                className="stateProfileChange"
                id="state"
                onChange={handleInputChange}
                placeholder="State"
                type={searchCountryResults.length > 0 && "hidden"}
              ></input>
              {searchStateResults.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 dropDownState rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                  {searchStateResults.map((result, index) => (
                    <li
                      key={index}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleSelectState(result)}
                    >
                      {result?.name}
                    </li>
                  ))}
                </ul>
              )}
              <input
                value={dropDownCity?.name}
                className="cityProfileChange "
                id="city"
                onChange={handleInputChange}
                placeholder="City"
                type={
                  (searchCountryResults.length > 0 ||
                    searchStateResults.length > 0) &&
                  "hidden"
                }
              ></input>
              {searchCityResults.length > 0 && (
                <ul className="absolute bg-white border border-gray-300 dropDownCity rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                  {searchCityResults.map((result, index) => (
                    <li
                      key={index}
                      className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                      onClick={() => handleSelectCity(result)}
                    >
                      {result?.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div
              style={{
                paddingLeft: "30px",
                marginTop: "5rem",
                marginBottom: "1rem",
              }}
            >
              <button className="flex justify-center items-center w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out">
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default ChangeProfile;
