import { apiSlice } from "../api/apiSlice";
export const EventListApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getEventList: builder.query({
      query: (data) => ({
        url: "https://gamedemoserver.happynation.global/events/list",
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    getEventDetails: builder.query({
      query: (data) => ({
        url: `https://gamedemoserver.happynation.global/events/detail/${data?.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data?.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
    signUpEvent: builder.mutation({
      query: (data) => ({
        url: `https://gamedemoserver.happynation.global/events/sign-up/${data.id}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${data.token}`,
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});
export const {
  useGetEventListQuery,
  useGetEventDetailsQuery,
  useSignUpEventMutation,
} = EventListApi;
