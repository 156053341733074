import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import lefticon from "../../assets/images/Group11246.png";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { setToLocalStorage } from "../../utils/local-storage";
import Substact from "../../assets/images/Subtract.png";
import {
  useCityInfoMutation,
  useCountryInfoQuery,
  useStateInfoMutation,
} from "../../features/auth/authAPI";
const CreateAccount2 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    year: "",
    gender: "",
    is_student: false,
    UniName: "",
  });
  const [isStudent, setIsStudent] = useState(false);
  useEffect(() => {
    // Disable scrolling
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const isStudentChange = () => {
    setIsStudent(!isStudent);
  };
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f17430",
      },
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    formData.is_student = isStudent;
    console.log(formData);
    if (isDesktop) {
      const personalDetails = {
        year: formData.year,
        is_student: formData.is_student,
        gender: formData.gender,
        UniName: formData.UniName,
      };
      const locationDetails = {
        state: formData.state,
        country: formData.country,
        city: formData.city,
      };
      setToLocalStorage("step2", JSON.stringify(personalDetails));
      const submittedValueCountry = document.getElementById("country").value;
      const submittedValueState = document.getElementById("state").value;
      const submittedValueCity = document.getElementById("city").value;
      const submittedData = {
        country: submittedValueCountry,
        city: submittedValueCity,
        state: submittedValueState,
      };
      setToLocalStorage("step3", JSON.stringify(submittedData));
      navigate("/tearms-condition");
    } else {
      setToLocalStorage("step2", JSON.stringify(formData));
      navigate("/signUp3");
    }
  };
  const yearsArray = [];
  for (let i = 1950; i <= 2010; i++) {
    yearsArray.push(i.toString());
  }
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [searchCountryResults, setSearchCountryResults] = useState([]);
  const [searchStateResults, setSearchStateResults] = useState([]);
  const [searchCityResults, setSearchCityResults] = useState([]);
  const [stateCountryData, setStateCountryData] = useState();
  const [stateStateData, setStateData] = useState();
  const [stateCityData, setStateCityData] = useState();
  const [dropDownCountry, setDropDownCountry] = useState();
  const [dropDownState, setDropDownState] = useState();
  const [dropDownCity, setDropDownCity] = useState();
  const { data: CountryData } = useCountryInfoQuery();
  const [
    cityInfo,
    {
      isLoading: isCityLoading,
      data: cityData,
      isSuccess: isCitySuccess,
      error: CityError,
    },
  ] = useCityInfoMutation();
  const [stateInfo, { isLoading, data: stateData, isSuccess, error }] =
    useStateInfoMutation();
  const handleInputChange1 = (e) => {
    const { id, value } = e.target;
    console.log(id, value);
    if (id === "country") {
      const filteredResults = stateCountryData.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
      console.log(filteredResults);
      if (filteredResults) {
        setSearchCountryResults(filteredResults);
        setDropDownCountry({ country: value });
      }
    }
    if (id === "state") {
      const filteredResults = stateStateData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownState({ state: value });
        setSearchStateResults(filteredResults);
      }
    }
    if (id === "city") {
      const filteredResults = stateCityData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownCity({ state: value });
        setSearchCityResults(filteredResults);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleSelectCountry = (contryValueSelected) => {
    setDropDownCountry(contryValueSelected);
    stateInfo({ country: contryValueSelected?.name });
    setSearchCountryResults([]);
  };
  const handleSelectState = (stateValueSelected) => {
    setDropDownState(stateValueSelected);
    cityInfo({
      country: dropDownCountry?.name,
      state: stateValueSelected?.name,
    });
    setSearchStateResults([]);
  };
  const handleSelectCity = (cityValueSelected) => {
    setDropDownCity(cityValueSelected);
    setSearchCityResults([]);
  };
  useEffect(() => {
    console.log(CountryData);
    if (CountryData?.length > 0) {
      setStateCountryData(CountryData);
    }
    if (stateData?.length > 0) {
      setStateData(stateData);
    }
    if (cityData?.length > 0) {
      setStateCityData(cityData);
    }
  }, [CountryData, stateData, cityData]);

  return (
    <div className="flex flex-col lg:flex-row h-[120vh] overflow-hidden">
      <div
        className="hidden lg:block flex-1 bg-no-repeat bg-cover h-full w-full"
        style={{
          backgroundImage: `url(${Substact})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex items-center justify-center h-full text-white text-3xl text-center font-noto-sans">
          <div className="transform lg:transform-none">
            <h1 className="font-bold ml-[-200px]">Welcome to the</h1>
            <h1 className="font-bold ml-[-120px]">Happy Nation Games</h1>
          </div>
        </div>
      </div>
      <div className="flex-1 flex items-center justify-center bg-[#fffbf4] mt-[-50px]">
        <div className="bg-[#fffbf4] w-[400px] h-[830px] relative ml-0 p-0 lg:p-0">
          <div className="mt-10 ml-1">
            <Link to={"/signUp1"}>
              <img
                src={lefticon}
                alt="backicn"
                className="h-8 w-8 relative bottom-[-100px] left-3"
              />
            </Link>
            <div className="flex justify-between items-center gap-2 w-[176px] h-1 relative top-20 left-[107px]">
              <div className="w-[43px] h-[3px] bg-[#cecece]"></div>
              <div className="w-[43px] h-[3px] bg-[#f17430]"></div>
              <div className="w-[43px] h-[3px] bg-[#cecece]"></div>
              {!isDesktop && <div className="w-10 h-1 bg-[#cecece]" />}
            </div>
          </div>
          <p className="flex justify-center text-2xl items-center h-[200px] w-[297px] text-[#1d1d1d] font-noto-sans-medium  text-center font-medium absolute top-[90px] left-[47px]">
            Create your account
          </p>
          <form onSubmit={handleSubmit}>
            <div className="relative">
              <select
                id="year"
                required
                onChange={handleInputChange}
                className="w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute top-[160px] left-[20px] "
              >
                <option value="" disabled selected>
                  Date of birth
                </option>
                {yearsArray.map((y) => (
                  <option value={y} key={y}>
                    {y}
                  </option>
                ))}
              </select>
            </div>
            <div className="relative">
              <select
                id="gender"
                required
                onChange={handleInputChange}
                className="w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute top-[215px] left-[20px]"
              >
                <option value="" disabled selected hidden>
                  Gender
                </option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
                <option value="Prefer not to share">Prefer not to share</option>
              </select>
            </div>
            <div className="relative">
              <FormGroup className="w-[334px] absolute left-[20px] top-[270px]">
                <FormControlLabel
                  control={
                    <ThemeProvider theme={theme}>
                      <Checkbox
                        onChange={isStudentChange}
                        color="primary"
                        sx={{ color: "#f10030" }}
                      />
                    </ThemeProvider>
                  }
                  label="I'm a student"
                />
              </FormGroup>
            </div>
            {isStudent && (
              <div className="relative">
                <input
                  onChange={handleInputChange}
                  className="w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute top-[330px] left-[20px]"
                  type="text"
                  id="UniName"
                  placeholder="University name"
                />
              </div>
            )}
            {isDesktop && (
              <div className={`relative ${isStudent ? "shifted" : ""}`}>
                <input
                  onChange={handleInputChange1}
                  className={`w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute ${
                    isStudent ? "top-[380px]" : "top-[320px]"
                  } left-[20px]`}
                  type="text"
                  id="country"
                  placeholder="Country"
                  value={dropDownCountry?.name}
                />
                {searchCountryResults.length > 0 && (
                  <ul className="absolute bg-white border border-gray-300 rounded optionWidth mt-[24rem] py-1 shadow-lg max-h-[8rem] overflow-y-auto">
                    {searchCountryResults.map((result, index) => (
                      <li
                        key={index}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleSelectCountry(result)}
                      >
                        {result?.name}
                      </li>
                    ))}
                  </ul>
                )}
                <input
                  onChange={handleInputChange1}
                  className={`w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute ${
                    isStudent ? "top-[430px]" : "top-[370px]"
                  } left-[20px]`}
                  id="state"
                  placeholder="State"
                  value={dropDownState?.name}
                  type={searchCountryResults.length > 0 && "hidden"}
                />
                {searchStateResults.length > 0 && (
                  <ul className="absolute bg-white border border-gray-300 mt-[26rem] rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                    {searchStateResults.map((result, index) => (
                      <li
                        key={index}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleSelectState(result)}
                      >
                        {result?.name}
                      </li>
                    ))}
                  </ul>
                )}
                <input
                  onChange={handleInputChange1}
                  className={`w-[310px] outline-none bg-[#fffbf4] border-b border-[#f17430] py-1 px-2 text-lg absolute ${
                    isStudent ? "top-[480px]" : "top-[420px]"
                  } left-[20px]`}
                  type={
                    (searchCountryResults.length > 0 ||
                      searchStateResults.length > 0) &&
                    "hidden"
                  }
                  id="city"
                  placeholder="City"
                  value={dropDownCity?.name}
                />
                {searchCityResults.length > 0 && (
                  <ul className="absolute bg-white border border-gray-300 mt-[28rem] rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                    {searchCityResults.map((result, index) => (
                      <li
                        key={index}
                        className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                        onClick={() => handleSelectCity(result)}
                      >
                        {result?.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
            <div className="pt-[550px] pl-[20px]">
              <button className="flex justify-center items-center w-[310px] h-[50px] rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount2;
