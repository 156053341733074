import React from "react";
import { Outlet } from "react-router-dom";
import MobileNavBar from "../Shared/Navbar/MobileNavBar";
const Secondary = () => {
  return (
    <>
      <Outlet></Outlet>
      <MobileNavBar></MobileNavBar>
    </>
  );
};
export default Secondary;
