import loading from "../../../assets/images/loading1.gif";
import "./LoadingPage.css";
const LoadingPage = () => {
  return (
    <div
      className="bg-cover bg-center flex flex-col justify-center items-center bg-gradient-to-b from-yellow-500 to-orange-500"
      style={{ height: "106vh" }}
    >
      <div className="mt-5 ml-8 border-transparent border-solid border-0 flex flex-col items-center">
        <img
          className="w-11/12 sm:w-.5/4 md:w-1/2 lg:w-2/4 h-auto"
          src={loading}
          alt="Loading image"
        />
      </div>
    </div>
  );
};
export default LoadingPage;
