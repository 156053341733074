import lefticon from "../../assets/images/Group11246.png";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Substact from "../../assets/images/Subtract.png";
const Termsandconditions = () => {
  const navigate = useNavigate();
  const [tearmsChecked, setTearmChecked] = useState(false);
  const handleTermChanged = () => {
    setTearmChecked(!tearmsChecked);
  };
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#f17430",
      },
    },
  });
  const handleSignUp = () => {
    console.log("hahah");
    navigate("/loading");
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="flex flex-col lg:flex-row h-[120vh] overflow-hidden">
      <div
        className="hidden lg:block lg:flex-1 bg-no-repeat bg-cover h-full w-full"
        style={{
          backgroundImage: `url(${Substact})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="flex items-center justify-center h-full text-white text-3xl text-center font-noto-sans">
          <div className="transform lg:transform-none">
            <h1 className="font-bold ml-[-200px]">Welcome to the</h1>
            <h1 className="font-bold ml-[-120px]">Happy Nation Games</h1>
          </div>
        </div>
      </div>
      <div className="right-half lg:flex justify-center items-center">
        <div className="bg-custom-bg w-full h-screen md:w-96 md:h-204 lg:w-390 lg:h-856 relative">
          <div className="mt-1 ml-1">
            <Link to={"/signUp3"}>
              <img
                src={lefticon}
                alt="backicn"
                className="h-8 w-8 relative bottom-[-40px] left-3"
              />
            </Link>
            <div className="flex justify-between items-center gap-2 w-[176px] h-1 relative top-3 left-[107px]">
              <div className="w-[43px] h-[3px] bg-[#cecece]"></div>
              <div className="w-[43px] h-[3px] bg-[#cecece]  "></div>
              {!isDesktop && (
                <div className="w-[43px] h-[3px] bg-[#cecece]"></div>
              )}
              <div className="w-[43px] h-[3px] bg-[#cecece] active"></div>
            </div>
            <p className="flex justify-center text-2xl items-center h-[200px] w-[297px] text-[#1d1d1d] font-noto-sans-medium  text-center font-medium absolute top-[1px] left-[47px]">
              Terms & Conditions
            </p>
            <p className="text-[#1d1d1f] flex justify-center  font-NotoSans-Medium h-20 absolute top-[8rem] left-[17px] w-[330px] text-justify m-0 p-0">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
              suscipit tellus aliquet vestibulum venenatis. Suspendisse ac
              tortor mollis, faucibus mi vitae, syvehicula lacus. Suspendisse
              ultricies nisl eget sem rutrum, tincidunt faucibus dui efficitur.
              Sed a ante diam. Nulla quis metus at arcu rutrum vestibulum nec id
              purus. Maecenas euismod convallis ante eu mattis. Duis dui nibh,
              lobortis quis est sollicitudin, sollicitudin commodo ex.
              <br></br>
              Aliquam volutpat, massa a iaculis varius, lacus eros euismod
              sapien, nec luctus eros quam in nibh. Sed sem felis, vehicula nec
              consectetur ultricies,
            </p>
            <div style={{ position: "relative" }} className="login">
              <div className="w-[334px] outline-none absolute left-[23px] p-1 pt-[460px]">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <ThemeProvider theme={theme}>
                        <Checkbox
                          onChange={handleTermChanged}
                          color="primary"
                          sx={{ color: "#f17430" }}
                        />
                      </ThemeProvider>
                    }
                    label="I agree with the terms and conditions"
                  />
                </FormGroup>
              </div>
            </div>
            <div className="pt-[500px] pl-[30px]">
              <button
                onClick={() => handleSignUp()}
                style={{ pasition: "absolute", top: 800 }}
                className={`flex justify-center items-center w-[310px] h-[50px] rounded-lg text-white font-medium text-lg leading-tight transition duration-200 ease-in-out focus:outline-none focus:ring-0 ${
                  tearmsChecked
                    ? "bg-gradient-to-br from-orange-500 to-yellow-500 hover:bg-orange-600"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Termsandconditions;
