import LogoutSharpIcon from "@mui/icons-material/LogoutSharp";
import "./Setting.css";
import { useLogOutMutation } from "../../features/auth/authAPI";
import { clearCookies, getCookies } from "../../utils/Cookies";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import ToggleOffSharpIcon from "@mui/icons-material/ToggleOffSharp";
import ToggleOnSharpIcon from "@mui/icons-material/ToggleOnSharp";
import { useEffect, useState } from "react";
import LoadingPage from "../Shared/LoadingPage/LoadingPage";
import { useNavigate } from "react-router-dom";
const Setting = () => {
  const [logout, { data, isLoading, error: responseError, isSuccess }] =
    useLogOutMutation();
  const accessToken = getCookies("accessToken");
  const refreshToken = getCookies("refreshToken");
  const LogOutButton = () => {
    const logoutData = {
      accessToken,
      refreshToken: {
        refresh_token: refreshToken,
      },
    };
    logout(logoutData);
  };
  const [toggle, setToggle] = useState(false);
  const changeToggle = () => {
    setToggle(!toggle);
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      clearCookies();
      navigate("/");
    }
    if (data) {
      console.log(data);
    }
  }, [isLoading, isSuccess, data]);
  if (isLoading) {
    return <LoadingPage></LoadingPage>;
  }
  const handleProfile = () => {
    navigate("/ChangeProfile");
  };
  return (
    <div className="mt-20 flex flex-col gap-5 ml-8">
      <div>
        <h1 className="font-bold">Account</h1>
        <form className="w-70 p-4 rounded-lg divide-y-2 divide-amber-500">
          <div className="flex justify-between mr-8 p-4">
            <h1 className="font-large ">Language</h1>
            <ArrowBackIosNewSharpIcon
              className="Logoutlogo"
              fontSize="medium"
            ></ArrowBackIosNewSharpIcon>
          </div>
          <div className="flex justify-between mr-8 p-4 mt-2">
            <h1 className="font-large ">Notifications</h1>
            {toggle ? (
              <ToggleOnSharpIcon
                className="ToggleOn"
                fontSize="large"
                onClick={changeToggle}
              ></ToggleOnSharpIcon>
            ) : (
              <ToggleOffSharpIcon
                className="ToggleOff"
                fontSize="large"
                onClick={changeToggle}
              ></ToggleOffSharpIcon>
            )}
          </div>
          <div
            onClick={() => handleProfile()}
            className="flex justify-between mr-8 p-4"
          >
            <h1 className="font-large ">Profile</h1>
            <ArrowBackIosNewSharpIcon
              className="Logoutlogo"
              fontSize="medium"
            ></ArrowBackIosNewSharpIcon>
          </div>
        </form>
      </div>
      <div>
        <h1 className="font-bold">Support</h1>
        <form className="w-70 p-4 rounded-lg divide-y-2 divide-amber-500">
          <div className="flex justify-between mr-8 p-4">
            <h1 className="font-large ">FAQs</h1>
            <ArrowBackIosNewSharpIcon
              className="Logoutlogo"
              fontSize="medium"
            ></ArrowBackIosNewSharpIcon>
          </div>
          <div className="flex justify-between mr-8 p-4">
            <h1 className="font-large ">Feedback</h1>
            <ArrowBackIosNewSharpIcon
              className="Logoutlogo"
              fontSize="medium"
            ></ArrowBackIosNewSharpIcon>
          </div>
        </form>
      </div>
      <div className="flex mb-[6rem] flex-col justify-center">
        <button className="w-80 h-10 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-sans font-medium">
          Save Changes
        </button>
        <button
          onClick={LogOutButton}
          className="mt-6 flex flex-row justify-center signOutCSS"
        >
          <LogoutSharpIcon
            className="Logoutlogo"
            fontSize="medium"
          ></LogoutSharpIcon>
          <h1 className="text-orange-500 font-medium ">Sign Out</h1>
        </button>
      </div>
    </div>
  );
};
export default Setting;
