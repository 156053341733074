import { createSlice } from "@reduxjs/toolkit";
import {
  getAuth,
  signOut,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  FacebookAuthProvider
} from "firebase/auth";
import app from "../../utils/firebase.config";
const auth = getAuth(app);
const googleAuth = new GoogleAuthProvider();
const appleProvider = new OAuthProvider('apple.com');
const fbProvider=new FacebookAuthProvider();
const initialState = {
  accessToken: undefined,
  user: undefined,
  userInfo: null,
  error: null,
  isLoading: false,
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    userLoggedIn: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.user = action.payload.user;
    },
    LoggedInUserInfoStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    LoggedInUserInfoSuccess: (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.userInfo = action.payload;
    },
    LoggedInUserInfoFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
const signInWithGoogle = () => {
  return signInWithPopup(auth, googleAuth);
};
const signInWithApple=()=>{
  return signInWithPopup(auth, appleProvider);
}
const signInWithFacebook=()=>{
  return signInWithPopup(auth, fbProvider);
}
export const {
  userLoggedIn,
  LoggedInUserInfoFailure,
  LoggedInUserInfoSuccess,
  LoggedInUserInfoStart,
} = authSlice.actions;
export default authSlice.reducer;
export const userInfo = (state) => state.auth.userInfo;
export {signInWithGoogle,signInWithApple,signInWithFacebook}
