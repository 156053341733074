import { useLocation, Link, useNavigate } from "react-router-dom";
import "./SocialChallengeDetails.css";
import { useEffect, useState } from "react";
import { getFromLocalStorage } from "../../../../../utils/local-storage";
import gratitudeChallengeDesktoppic from "../../../../../assets/images/SD3.png";
import love from "../../../../../assets/images/love.png";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
const SocialChallengeDetails = () => {
  const d = JSON.parse(getFromLocalStorage("localData"));
  const navigate = useNavigate();
  const { social: SocialChallengeData } = d;
  const [description, setDescription] = useState([]);
  useEffect(() => {
    if (SocialChallengeData?.description) {
      setDescription(SocialChallengeData.description.split(/\r\n\r\n|\r\n/));
    }
  }, [SocialChallengeData?.description]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth, SocialChallengeData]);
  const navigate1 = useNavigate();
  const MentalChallengeClick = () => {
    navigate1("/ChallengePages");
  };
  return (
    <div className="mb-5">
      {isDesktop ? (
        <div className="flex">
          <div className="w-2/12 ">
            <div
              onClick={() => navigate(-1)}
              className="overlap-groupPhyDetails"
            >
              <ExpandCircleDownIcon
                className="mt-[-33rem] ml-4"
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "2.0rem",
                  fill: "none",
                  stroke: "rgb(246, 170, 26)",
                  strokeWidth: "1.5px",
                }}
              />
            </div>
          </div>
          <div className="w-6/12 ml-[-8rem] mt-[2rem]">
            <h1 className="text-2xl font-bold text-gray-800">
              {SocialChallengeData?.title}
            </h1>
            <p className="text-sm">Challenge {SocialChallengeData?.day}</p>
            <div className="mt-5">
              <h1 className="text-xl font-bold text-gray-800">
                {SocialChallengeData?.description ? description[0] : ""}
              </h1>
              <h1 className="text-xl font-bold text-gray-800">
                {SocialChallengeData?.description ? description[1] : ""}
              </h1>
              <h1 className="text-xl font-bold text-gray-800">
                {SocialChallengeData?.description ? description[2] : ""}
              </h1>
            </div>
          </div>
          <div>
            <div className="relative  mt-[-2.85rem] mr-[2rem]">
              <img
                className="h-[40rem] w-full ml-12"
                src={gratitudeChallengeDesktoppic}
                alt="Gratitude Challenge"
              />

              <img
                className="absolute mt-[-37rem] ml-6 w-16 z-10"
                src={love}
                alt="Dumber"
              />
              <div
                onClick={() => MentalChallengeClick()}
                className="absolute bottom-0 right-0 mb-[-2rem]  mr-4 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-lg shadow-lg card-body  h-[4rem] w-[12rem] p-[0rem]"
              >
                <div className="flex ml-3 items-center h-full">
                  <h2 className=" text-white text-left">End of Survey</h2>
                  <ArrowForwardIosOutlinedIcon
                    style={{ color: "white" }}
                    className="mt-2 ml-4"
                  ></ArrowForwardIosOutlinedIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="iphoneAct">
          <div className="div">
            <div
              className="overlap"
              style={{
                backgroundImage: `url(${SocialChallengeData?.image})`,
              }}
            >
              {/* <div onClick={() => navigate(-1)} className="overlap-group">
                <div className="ellipse" />
                <img
                  className="vector"
                  alt="Vector"
                  src="https://c.animaapp.com/VPpVWYX1/img/vector-72.svg"
                />
              </div> */}
              <ExpandCircleDownIcon
                onClick={() => navigate(-1)}
                className="mt-[2rem] ml-4"
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "2.0rem",
                  fill: "none",
                  stroke: "rgb(246, 170, 26)",
                  strokeWidth: "1.5px",
                }}
              />
              <img
                className="group"
                alt="Group"
                src="https://c.animaapp.com/VPpVWYX1/img/group-11187@2x.png"
              />
            </div>
            <div className="text-wrapper">{SocialChallengeData?.title}</div>
            <div className="text-wrapper-2 ml-8">
              Challenge {SocialChallengeData?.day}
            </div>
            <p className="para1">
              {SocialChallengeData?.description ? description[0] : ""}
            </p>
            <p className="text-wrapper-3">
              {SocialChallengeData?.description ? description[1] : ""}
            </p>
            <p className="text-wrapper-4">
              {SocialChallengeData?.description ? description[2] : ""}
            </p>
            <Link to={"/ChallengePages"}>
              <div className="overlap-2">
                <div className="rectangle" />
                <p className="text-wrapper-5">End of the day survey</p>
                <img
                  className="img"
                  alt="Vector"
                  src="https://c.animaapp.com/VPpVWYX1/img/vector-71.svg"
                />
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default SocialChallengeDetails;
{
  /* <div className="flex justify-between">
<div className="w-1/3 mt-10 ml-10 mr-10">
  <div onClick={() => navigate(-1)} className="overlap-group">
    <ExpandCircleDownIcon
      className="mt-[-33rem] ml-4"
      style={{
        transform: "rotate(90deg)",
        fontSize: "2.0rem",
        fill: "none",
        stroke: "rgb(246, 170, 26)",
        strokeWidth: "1.5px",
      }}
    />
  </div>
  <div>
    <h1 className="text-2xl font-bold text-gray-800">
      {SocialChallengeData?.title}
    </h1>
    <p className="text-sm">Challenge {SocialChallengeData?.day}</p>
    <div className="mt-5">
      <h1 className="text-xl font-bold text-gray-800">
        {SocialChallengeData?.description ? description[0] : ""}
      </h1>
      <h1 className="text-xl font-bold text-gray-800">
        {SocialChallengeData?.description ? description[1] : ""}
      </h1>
      <h1 className="text-xl font-bold text-gray-800">
        {SocialChallengeData?.description ? description[2] : ""}
      </h1>
    </div>
  </div>
</div>
<div>
  <div className="w-2/3 mt-10 mr-10 flex justify-center items-center">
    <img className="mt-[-19rem] ml-3 z-10" src={flower}></img>
    <img
      className="mr-[-25rem] h-[30rem]"
      src={gratitudeChallengeDesktoppic}
      alt="Gratitude Challenge"
    ></img>
    <div className="mt-[25rem] mr-[6rem] card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizekindnessDetails">
      <div className="flex justify-between mt-5 ml-5">
        <h1 className="text-sm text-white">End of the day Survey</h1>
        <ArrowForwardIosOutlinedIcon
          onClick={() => MentalChallengeClick()}
          className="mt-2.7"
          style={{ color: "white" }}
        ></ArrowForwardIosOutlinedIcon>
      </div>
    </div>
  </div>
</div>
</div> */
}
