import { useEffect, useState } from "react";
import lefticon from "../../assets/images/Group11246.png";
import "./CreateAccount3.css";
import { setToLocalStorage } from "../../utils/local-storage";
import { Link, useNavigate } from "react-router-dom";
import {
  useCityInfoMutation,
  useCountryInfoQuery,
  useStateInfoMutation,
} from "../../features/auth/authAPI";
const CreateAccount3 = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    country: "",
    state: "",
    city: "",
  });
  const [searchCountryResults, setSearchCountryResults] = useState([]);
  const [searchStateResults, setSearchStateResults] = useState([]);
  const [searchCityResults, setSearchCityResults] = useState([]);
  const { data: CountryData } = useCountryInfoQuery();
  const [stateInfo, { isLoading, data: stateData, isSuccess, error }] =
    useStateInfoMutation();
  const [
    cityInfo,
    {
      isLoading: isCityLoading,
      data: cityData,
      isSuccess: isCitySuccess,
      error: CityError,
    },
  ] = useCityInfoMutation();
  const [dropDownCountry, setDropDownCountry] = useState();
  const [dropDownState, setDropDownState] = useState();
  const [dropDownCity, setDropDownCity] = useState();
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    if (id === "country") {
      const filteredResults = stateCountryData.filter((country) =>
        country.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setSearchCountryResults(filteredResults);
        setDropDownCountry({ country: value });
      }
    }
    if (id === "state") {
      const filteredResults = stateStateData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownState({ state: value });
        setSearchStateResults(filteredResults);
      }
    }
    if (id === "city") {
      const filteredResults = stateCityData.filter((state) =>
        state.name.toLowerCase().includes(value.toLowerCase())
      );
      if (filteredResults) {
        setDropDownCity({ state: value });
        setSearchCityResults(filteredResults);
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };
  const handleSelectCountry = (contryValueSelected) => {
    setDropDownCountry(contryValueSelected);
    stateInfo({ country: contryValueSelected?.name });
    setSearchCountryResults([]);
  };
  const handleSelectState = (stateValueSelected) => {
    setDropDownState(stateValueSelected);
    cityInfo({
      country: dropDownCountry?.name,
      state: stateValueSelected?.name,
    });
    setSearchStateResults([]);
  };
  const handleSelectCity = (cityValueSelected) => {
    setDropDownCity(cityValueSelected);
    setSearchCityResults([]);
  };
  const [stateCountryData, setStateCountryData] = useState();
  const [stateStateData, setStateData] = useState();
  const [stateCityData, setStateCityData] = useState();
  useEffect(() => {
    if (CountryData?.length > 0) {
      setStateCountryData(CountryData);
    }
    if (stateData?.length > 0) {
      setStateData(stateData);
    }
    if (cityData?.length > 0) {
      setStateCityData(cityData);
    }
  }, [CountryData, stateData, cityData]);
  const HandleSubmit = (e) => {
    e.preventDefault();
    const submittedValueCountry = document.getElementById("country").value;
    const submittedValueState = document.getElementById("state").value;
    const submittedValueCity = document.getElementById("city").value;
    const submittedData = {
      country: submittedValueCountry,
      city: submittedValueCity,
      state: submittedValueState,
    };
    setToLocalStorage("step3", JSON.stringify(submittedData));
    navigate("/tearms-condition");
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="login-container">
      <div
        className="bg-custom-bg w-full h-screen md:w-96 md:h-204 lg:w-390 lg:h-856 relative"
        style={{ height: "100vh" }}
      >
        <div className="mt-1 ml-5">
          <div>
            <Link to={"/signUp2"}>
              <img src={lefticon} alt="backicn" className="backicon" />
            </Link>
            <div className="dash2flex">
              <div className="dash"></div>
              {!isDesktop && <div className="dash"></div>}
              <div className="dash active"></div>
              <div className="dash"></div>
            </div>
            <p className="p1">Create your account</p>
          </div>
          <form className="mt-40" onSubmit={HandleSubmit}>
            <input
              className="country"
              id="country"
              onChange={handleInputChange}
              placeholder="Country"
              value={dropDownCountry?.name}
            ></input>
            {searchCountryResults.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 rounded optionWidth dropDownCountry py-1 shadow-lg max-h-40 overflow-y-auto">
                {searchCountryResults.map((result, index) => (
                  <li
                    key={index}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleSelectCountry(result)}
                  >
                    {result?.name}
                  </li>
                ))}
              </ul>
            )}
            <input
              value={dropDownState?.name}
              className="state"
              id="state"
              onChange={handleInputChange}
              placeholder="State"
              type={searchCountryResults.length > 0 && "hidden"}
            ></input>
            {searchStateResults.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 dropDownState rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                {searchStateResults.map((result, index) => (
                  <li
                    key={index}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleSelectState(result)}
                  >
                    {result?.name}
                  </li>
                ))}
              </ul>
            )}
            <input
              value={dropDownCity?.name}
              className="city"
              id="city"
              onChange={handleInputChange}
              placeholder="City"
              type={
                (searchCountryResults.length > 0 ||
                  searchStateResults.length > 0) &&
                "hidden"
              }
            ></input>
            {searchCityResults.length > 0 && (
              <ul className="absolute bg-white border border-gray-300 dropDownCity rounded optionWidth  py-1 shadow-lg max-h-40 overflow-y-auto">
                {searchCityResults.map((result, index) => (
                  <li
                    key={index}
                    className="cursor-pointer px-4 py-2 hover:bg-gray-100"
                    onClick={() => handleSelectCity(result)}
                  >
                    {result?.name}
                  </li>
                ))}
              </ul>
            )}
            <div style={{ paddingTop: "325px", paddingLeft: "15px" }}>
              <button className="flex justify-center items-center w-80 h-14 rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-lg leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateAccount3;
