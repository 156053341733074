import { Link } from "react-router-dom";
import notFound from "../../../assets/images/notFound.png";
import "./NotFoundPage.css";
const NotFoundPage = () => {
  return (
    <div className="bg-[#fffbf4] flex flex-row justify-center w-full">
      <div className="bg-[#fffbf4] overflow-hidden w-[387px] h-[872px] relative">
        <div className="absolute w-[205px] top-[232px] left-[91px] [font-family:'Noto_Sans-Bold',Helvetica] font-bold text-[#1d1d1f] text-[24px] text-center tracking-[0] leading-[normal]">
          Uhmm...
        </div>
        <p className="absolute w-[306px] top-[274px] left-[41px] [font-family:'Noto_Sans-Regular',Helvetica] font-normal text-[#1d1d1f] text-[16px] text-center tracking-[0] leading-[normal]">
          Looks like this page is not available at the moment. In the meantime
          let’s head back home
        </p>
        <div className="imageNotFound">
          <img src={notFound} className="w-40"></img>
        </div>
        <div className="absolute w-[336px] h-[52px] top-[546px] left-[28px]">
          <div className="relative w-[334px] h-[52px] rounded-[5px] [background:linear-gradient(180deg,rgb(246,170,26)_0%,rgb(238,78,54)_100%)]">
            <Link
              to={"/ChallengePages"}
              className="absolute w-[154px] h-[28px] top-[12px] left-[89px] [font-family:'Noto_Sans-Medium',Helvetica] font-medium text-[#f9f6f2] text-[16px] text-center tracking-[0] leading-[normal]"
            >
              Go to Home
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFoundPage;
