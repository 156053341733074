import { useNavigate, useParams, Link } from "react-router-dom";
import {
  useGetEventDetailsQuery,
  useSignUpEventMutation,
} from "../../../features/EventList/EventListAPI";
import { getCookies } from "../../../utils/Cookies";
import "./EventDetails.css";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SpeedIcon from "@mui/icons-material/Speed";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import LinkIcon from "@mui/icons-material/Link";
import { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ticktock from "../../../assets/images/tiktok.svg";
import xTwitter from "../../../assets/images/square-x-twitter.svg";
import { useUserInfoQuery } from "../../../features/auth/authAPI";
import eventDetailPic from "../../../assets/images/EventDetails.jpeg";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import backgroundImg from "../../../assets/images/PD19_easy.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { format, parseISO } from "date-fns";
import dump from "../../../assets/images/Group 11178.png";
import xicon from "../../../assets/images/xicon.jpeg";
import Instagram from "../../../assets/images/instagram.jpeg";
import tiktok from "../../../assets/images/tiktok.jpeg";
const formatDate = (dateString) => {
  const date = parseISO(dateString);
  return format(date, "MMMM do, EEEE");
};
const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(":");
  return `${hours}:${minutes}`;
};
const EventDetails = () => {
  const accessCode = getCookies("accessToken");
  const { id } = useParams();
  const navigate = useNavigate();
  const data = {
    token: accessCode,
    id: id,
  };
  const {
    data: eventDetails,
    isLoading,
    isSuccess,
  } = useGetEventDetailsQuery(data);
  //console.log(eventDetails);
  const {
    data: userData,
    error,
    isLoading: userInfoLoading,
  } = useUserInfoQuery(data);
  const [
    signUpEvent,
    {
      isLoading: eventSignUpLoading,
      data: eventSignUpData,
      isSuccess: eventSignUpSuccess,
      error: eventSignUpresponseError,
    },
  ] = useSignUpEventMutation();
  const [instructor1, setInstructor1] = useState(true);
  const [instructor2, setInstructor2] = useState(false);
  const [instructorDetails, setInstructorDetails] = useState();
  useEffect(() => {
    setInstructorDetails(eventDetails?.instructor[0]);
  }, [eventDetails]);
  const ChangeCLick = (instructorName) => {
    if (instructorName === "Instructor1") {
      setInstructor1(true);
      setInstructor2(false);
      setInstructorDetails(eventDetails?.instructor[0]);
    } else if (instructorName === "Instructor2") {
      setInstructor1(false);
      setInstructor2(true);
      setInstructorDetails(eventDetails?.instructor[1]);
    }
  };
  const handleSignup = () => {
    signUpEvent(data);
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  if (isLoading) {
    console.log("loading..");
  }
  return (
    !isLoading &&
    (isDesktop ? (
      <div className="flex">
        <div className="w-2/12 ">
          <div onClick={() => navigate(-1)} className="overlap-groupPhyDetails">
            <ExpandCircleDownIcon
              className="mt-[-33rem] ml-4"
              style={{
                transform: "rotate(90deg)",
                fontSize: "2.0rem",
                fill: "none",
                stroke: "rgb(246, 170, 26)",
                strokeWidth: "1.5px",
              }}
            />
          </div>
        </div>
        <div className="w-6/12">
          <div className="ml-[-5rem]">
            <div>
              <h1 className="text-2xl font-bold">{eventDetails?.title}</h1>
            </div>
            <div className="mt-4">
              <div>
                <a
                  href="https://www.google.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-2 mt-2"
                >
                  <CalendarMonthIcon></CalendarMonthIcon>
                  {formatDate(eventDetails?.date)}
                </a>
                <h1 className="flex gap-2 mt-2">
                  <AccessTimeFilledIcon></AccessTimeFilledIcon>{" "}
                  {formatTime(eventDetails?.start_time)}-
                  {formatTime(eventDetails?.end_time)}
                </h1>
                <a
                  href={eventDetails?.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex gap-2 mt-2"
                >
                  <LinkIcon sx={{ transform: "rotate(120deg)" }}></LinkIcon>{" "}
                  Link to the event
                </a>
                <h1 className="flex gap-2 mt-2">
                  <FitnessCenterIcon></FitnessCenterIcon>
                  {eventDetails?.title}
                </h1>
              </div>
              <h1 className="mt-2 flex mt-2 gap-4">
                <img src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"></img>{" "}
                {eventDetails?.requirements}
              </h1>
              <h1 className="mt-4"> {eventDetails?.description}</h1>
            </div>
            <div className="mt-6">
              <div className="flex gap-2">
                <div>
                  <img
                    className="w-[13rem] h-[13rem] mt-5"
                    src={eventDetails?.instructor[0].image}
                  ></img>
                </div>
                <div className="mt-4 ml-4">
                  <h1 className="text-2xl font-bold">
                    {eventDetails?.instructor[0].name}
                  </h1>
                  <div className="flex gap-4 mt-4">
                    <a
                      href="https://twitter.com/HappyNation"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={xicon}></img>
                    </a>
                    <a
                      href="https://www.tiktok.com/@happynation.global/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={tiktok}></img>
                    </a>
                    <a
                      href="https://www.instagram.com/happynation.global/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon></InstagramIcon>
                    </a>
                  </div>
                  <h1 className="w-[25rem]">
                    {eventDetails?.instructor[0].description}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="relative  mt-[-2.85rem] mr-[2rem]">
            <img
              className="h-[40rem] w-full ml-12"
              src={backgroundImg}
              alt="Gratitude Challenge"
            />

            <div
              onClick={() => handleSignup()}
              className="absolute bottom-0 right-0 mb-[-2rem]  mr-4 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-lg shadow-lg card-body  w-[16rem] h-[5rem] p-[0rem]"
            >
              <div className="flex  items-center h-full">
                <div className="flex ml-[-24rem] justify-between">
                  {userData?.events.includes(parseInt(id)) ? (
                    <h1 className="signUptext text-white text-left">Cancel</h1>
                  ) : (
                    <h1 className="signUptext text-white text-left">
                      Sign up for event
                    </h1>
                  )}
                  <ArrowForwardIosOutlinedIcon
                    style={{ color: "white" }}
                    className="ArrowRightDetailsEve"
                  ></ArrowForwardIosOutlinedIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>
        <div className="mainDiv relative">
          <div>
            <img src={eventDetailPic} alt="Event" className="w-full" />
            <ArrowBackIosNewIcon
              onClick={() => navigate(-1)}
              className="backButton"
              fontSize="large"
            />
            <button
              onClick={() => handleSignup()}
              className="flex justify-center items-center rounded-lg bg-gradient-to-br from-orange-500 to-yellow-500 text-white font-medium text-xl leading-tight hover:bg-orange-600 focus:outline-none focus:ring-0 transition duration-200 ease-in-out absolute buttonSignup transform -translate-x-1/2"
            >
              {userData?.events.includes(parseInt(id)) ? (
                <h1>Cancel</h1>
              ) : (
                <h1> Sign up for event</h1>
              )}
              <ArrowForwardIosIcon />
            </button>
          </div>
          <div className="ml-4">
            {isLoading ? (
              <h1>Loading</h1>
            ) : (
              <>
                <div>
                  <h1 className="text-2xl font-bold">{eventDetails?.name}</h1>
                  <h1 className="font-bold mt-3">
                    {format(new Date(eventDetails?.date), "MMMM do, EEEE")} -{" "}
                    {format(
                      new Date(`2000-01-01T${eventDetails?.end_time}`),
                      "hh:mm"
                    )}
                    -
                    {format(
                      new Date(`2000-01-01T${eventDetails?.start_time}`),
                      "hh:mm"
                    )}
                  </h1>
                  <h1>
                    <LinkIcon className="LinkIconRotate"></LinkIcon>
                    <a
                      href={eventDetails?.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="underline text-xl text-orange-500 ml-2"
                    >
                      Link to the event{" "}
                    </a>
                  </h1>
                  <h1 className="font-bold mt-3">
                    {eventDetails?.description}
                  </h1>
                  <div className="mt-6">
                    <div className="overlapEventDetails">
                      <div
                        className={`rectangleModerateEventDetails ${
                          instructor1 && `toggleBackgroundEventDetails`
                        }`}
                      />
                      <div
                        className={`${
                          instructor1
                            ? `toggleTextEventDetails`
                            : `toggleRevEventDetails`
                        } text-wrapper-ModerateEventDetails mt-[0.5rem]`}
                        onClick={() => ChangeCLick("Instructor1")}
                      >
                        {eventDetails?.instructor[0]?.name}
                      </div>
                      <div
                        className={`rectangleIntenseEventDetails ${
                          instructor2 && `toggleBackgroundEventDetails`
                        }`}
                      />
                      <div
                        className={`${
                          instructor2
                            ? `toggleTextEventDetails`
                            : `toggleRevEventDetails`
                        } text-wrapper-IntenseEventDetails mt-[0.5rem]`}
                        onClick={() => ChangeCLick("Instructor2")}
                      >
                        {eventDetails?.instructor[1]?.name}
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div className="flex">
                      <img
                        className="instructorImage"
                        src={instructorDetails?.image}
                      ></img>
                      <div className="instructorProfile ml-4">
                        <h1 className="text-2xl font-bold">
                          {instructorDetails?.name}
                        </h1>
                        <h1 className="text-xl font-bold">Photo Grapher</h1>
                        <div className="flex mt-3 gap-2">
                          <a
                            href={instructorDetails?.ig_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <InstagramIcon></InstagramIcon>
                          </a>
                          <a
                            href={instructorDetails?.yt_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <YouTubeIcon></YouTubeIcon>
                          </a>
                          <a
                            href={instructorDetails?.tiktok_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img className="w-5 h-5" src={ticktock}></img>
                          </a>
                          <a
                            href={instructorDetails?.twitter_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img className="w-5 h-5" src={xTwitter}></img>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <h1>{instructorDetails?.description}</h1>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    ))
  );
};

export default EventDetails;
//  <div className="flex justify-between ">
// <div className="w-1/3 mt-10 ml-10 mr-10 flex flex-row">
//   <div onClick={() => navigate(-1)} className="overlap-group">
//     <ExpandCircleDownIcon
//       className="mt-[-33rem] ml-4"
//       style={{
//         transform: "rotate(90deg)",
//         fontSize: "2.0rem",
//         fill: "none",
//         stroke: "rgb(246, 170, 26)",
//         strokeWidth: "1.5px",
//       }}
//     />
//   </div>
//   <div className="ml-[4rem]">
//     <div>
//       <h1 className="text-2xl font-bold">{eventDetails?.title}</h1>
//     </div>
//     <div className="mt-4">
//       <div>
//         <a
//           href="https://www.google.com"
//           target="_blank"
//           rel="noopener noreferrer"
//           className="flex gap-2 mt-2"
//         >
//           <CalendarMonthIcon></CalendarMonthIcon>
//           {formatDate(eventDetails?.date)}
//         </a>
//         <h1 className="flex gap-2 mt-2">
//           <AccessTimeFilledIcon></AccessTimeFilledIcon>{" "}
//           {formatTime(eventDetails?.start_time)}-
//           {formatTime(eventDetails?.end_time)}
//         </h1>
//         <a
//           href={eventDetails?.link}
//           target="_blank"
//           rel="noopener noreferrer"
//           className="flex gap-2 mt-2"
//         >
//           <LinkIcon sx={{ transform: "rotate(120deg)" }}></LinkIcon>{" "}
//           Link to the event
//         </a>
//         <h1 className="flex gap-2 mt-2">
//           <FitnessCenterIcon></FitnessCenterIcon>
//           {eventDetails?.title}
//         </h1>
//       </div>
//       <h1 className="mt-2 flex mt-2 gap-4">
//         <img src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"></img>{" "}
//         {eventDetails?.requirements}
//       </h1>
//       <h1 className="mt-4"> {eventDetails?.description}</h1>
//     </div>
//     <div className="mt-6">
//       <div className="flex gap-2">
//         <div>
//           <img
//             className="w-[30rem] h-[10rem] mt-5"
//             src={eventDetails?.instructor[0].image}
//           ></img>
//         </div>
//         <div className="mt-4 ml-4">
//           <h1 className="text-2xl font-bold">
//             {eventDetails?.instructor[0].name}
//           </h1>
//           <div className="flex gap-4 mt-4">
//             <a
//               href="https://twitter.com/HappyNation"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={xicon}></img>
//             </a>
//             <a
//               href="https://www.tiktok.com/@happynation.global/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <img src={tiktok}></img>
//             </a>
//             <a
//               href="https://www.instagram.com/happynation.global/"
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <InstagramIcon></InstagramIcon>
//             </a>
//           </div>
//           <h1>{eventDetails?.instructor[0].description}</h1>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// <div className="w-2/3 mt-10 mr-10 flex justify-center items-center">
//   <img
//     className="mt-[-19rem] ml-6 w-16 z-10"
//     //src={dumber}
//   ></img>
//   <img
//     className="mr-[-30rem] h-[35rem]"
//     src={backgroundImg}
//     alt="Gratitude Challenge"
//   ></img>
//   <div
//     onClick={() => handleSignup()}
//     className="mt-[32rem] ml-[4rem] card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeEvantDetailsBack"
//   >
//     <div className="card-body cardLeftEventDetailsNext">
//       //<h2 className="signUptext text-white text-left">
//       //  Sign Up for event
//       //</h2>
//       {userData?.events.includes(parseInt(id)) ? (
//         <h1 className="signUptext text-white text-left">Cancel</h1>
//       ) : (
//         <h1 className="signUptext text-white text-left"> Sign up for event</h1>
//       )}
//       <div className="flex flex-col justify-between  ArrowLeft">
//         <ArrowForwardIosOutlinedIcon
//           style={{ color: "white" }}
//           className="ArrowevnetRightDetails"
//         ></ArrowForwardIosOutlinedIcon>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
