// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.submitButton{
  height: 3rem;
  width: 19rem;
}
.inputSurvey{
  width: 20rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/FirstPage/DailySurvey/DailySurvey.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;AACA;EACE,YAAY;AACd","sourcesContent":[".submitButton{\n  height: 3rem;\n  width: 19rem;\n}\n.inputSurvey{\n  width: 20rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
