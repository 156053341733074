// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rectangleBigMadels {
  background-color: #f9f6f2;
  box-shadow: 4px 6px 8px 1px #00000040;
  height: 180px;
  left: 15px;
  position: absolute;
  top: 590px;
  width: 363px;
  border-radius: 10px 10px 10px 10px;
}
.yourProgress {
  margin-left: -1.5rem;
}
.challengeday {
  margin-top: 1rem;
  margin-left: -3rem;
  margin-right: 1rem;
}
.MedalPic{
  margin-left: 1rem;
}
.longestStreak{
  margin-top: 1rem;
  margin-left: 1rem;
 /* margin-right: 1rem; */
}
`, "",{"version":3,"sources":["webpack://./src/Pages/ProfilePage/Madels/Madels.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,qCAAqC;EACrC,aAAa;EACb,UAAU;EACV,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,kCAAkC;AACpC;AACA;EACE,oBAAoB;AACtB;AACA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,iBAAiB;CAClB,wBAAwB;AACzB","sourcesContent":[".rectangleBigMadels {\n  background-color: #f9f6f2;\n  box-shadow: 4px 6px 8px 1px #00000040;\n  height: 180px;\n  left: 15px;\n  position: absolute;\n  top: 590px;\n  width: 363px;\n  border-radius: 10px 10px 10px 10px;\n}\n.yourProgress {\n  margin-left: -1.5rem;\n}\n.challengeday {\n  margin-top: 1rem;\n  margin-left: -3rem;\n  margin-right: 1rem;\n}\n.MedalPic{\n  margin-left: 1rem;\n}\n.longestStreak{\n  margin-top: 1rem;\n  margin-left: 1rem;\n /* margin-right: 1rem; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
