import React, { useState } from "react";
import ReactPlayer from "react-player";
import { FaTimes, FaPlay, FaPause, FaVolumeMute, FaVolumeUp } from "react-icons/fa"; 
import "./VedioPlayer.css";

const VideoPlayer = ({ videoSource, isOpen, onClose }) => {
  const [isPlaying, setIsPlaying] = useState(true);
  const [isMuted, setIsMuted] = useState(false);
  const [showControls, setShowControls] = useState(false);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleMuteToggle = () => {
    setIsMuted(!isMuted);
  };

  const handleMouseEnter = () => {
    setShowControls(true);
  };

  const handleMouseLeave = () => {
    setShowControls(false);
  };

  return (
    <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ${isOpen ? '' : 'hidden'}`}>
      <div className="fixed inset-0 flex items-center justify-center z-50">
        <div className="modal-box bg-transparent rounded-lg shadow-lg relative p-1" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className="video-responsive">
            <ReactPlayer
              controls={false}
              url={videoSource?.vedioData}
              playing={isPlaying}
              muted={isMuted}
              width="100%"
              height="100%"
            />
            {showControls && (
              <div className="controls">
                <button onClick={handlePlayPause} className="play-pause">
                  {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
                <button onClick={handleMuteToggle} className="mute-toggle">
                  {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                </button>
              </div>
            )}
            <button className="absolute top-0 right-0 mt-1 mr-2 text-gray-100 hover:text-gray-800" onClick={onClose}>
              <FaTimes />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoPlayer;
