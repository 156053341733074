import { useNavigate } from "react-router-dom";
import "./DailyPrizes.css";
import { useEffect, useState } from "react";
import instagram from "../../../assets/images/instagram1.jpeg";
import tiktok from "../../../assets/images/tiktok1.jpeg";
import twiter from "../../../assets/images/Twiter.jpeg";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import subscription from "../../../assets/images/Subscription.jpeg";
import fitness from "../../../assets/images/Fitness.jpeg";
import VedioPlayer from "../../../utils/VedioPlayer/VedioPlayer";
const DailyPrizes = ({ data }) => {
  const navigate = useNavigate();
  const checkClick = (prize) => {
    navigate("/ChallengePages/daily-prize-details", {
      state: { DailyPrizesData: prize },
    });
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [playerData, setPlayerData] = useState();
  const openModal = (vedioData) => {
    setPlayerData({
      CloseFunction: closeModal,
      vedioData: vedioData,
    });
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  return (
    <div>
      {isDesktop ? (
        <div className="ml-[7rem] mt-16">
          <h1 className="font-bold text-xl mt-8">Daily prizes</h1>
          <div className="mt-12 flex mb-5">
            {data?.map((prize) => (
              <div className="max-w-xs bg-[#F9F6F2] bg-white border border-gray-200 rounded-lg shadow-xl dark:bg-gray-800 dark:border-gray-700">
                <img
                  class="rounded-t-lg"
                  src="https://c.animaapp.com/NjN8xSjd/img/subtract-2.svg"
                  alt=""
                ></img>
                <PlayCircleFilledWhiteIcon
                  onClick={() => openModal(prize?.video_link)}
                  className="mt-[-13rem] ml-[7rem]"
                  sx={{
                    fontSize: 55,
                    color: "#f17430cc",
                  }}
                ></PlayCircleFilledWhiteIcon>
                {isModalOpen && (
                  <VedioPlayer
                    videoSource={playerData}
                    isOpen={isModalOpen}
                    onClose={closeModal}
                  />
                )}
                <img
                  className="ml-2 w-20 mt-[-6rem]"
                  alt="Group"
                  src={prize?.logo}
                />
                <div className="p-5">
                  <a href="#">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                      {prize?.company_name}
                    </h5>
                  </a>
                  <div className="">
                    <h1 className="flex gap-3">
                      <img src={fitness}></img>
                      {prize?.content}
                    </h1>
                    <h1 className="flex gap-3 mt-2">
                      <img src={subscription}></img> {prize?.prize_name}
                    </h1>
                  </div>
                  <p className="mb-3 mt-3 font-normal text-gray-700 dark:text-gray-400">
                    {prize?.description}
                  </p>
                  <div className="flex gap-6">
                    <img className="w-4 h-4" src={tiktok}></img>
                    <img className="w-4 h-4" src={instagram}></img>
                    <img className="w-4 h-4" src={twiter}></img>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="mb-20 mt-6 flex justify-center flex-col items-center ">
          <h1 className="font-bold text-xl">Daily prizes</h1>
          <div className="flex gap-8 mt-8 mb-4 justify-start">
            {data?.map((prize, index) => (
              <div
                onClick={() => checkClick(prize)}
                key={index}
                className="flex flex-col items-center"
              >
                <img className="w-20 h-20" alt="Group" src={prize?.logo} />
                <h1 style={{ color: "#f17430cc" }} className="underline">
                  Learn more
                </h1>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default DailyPrizes;
{
  //onClick={() => checkClick(prize)}
  /* <>
<div className="text-wrapper-4">Daily prizes</div>
<div className="flex group-10">
  {data?.map((prize) => (
    <div>
      <img className="logo" alt="Group" src={prize.logo} />
      <div onClick={() => checkClick(prize)} className="text-wrapper-18">
        Learn more
      </div>
    </div>
  ))}
</div>
</> */
}
