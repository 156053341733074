import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  challengeData: null,
  isLoading: false,
  error: null,
};
const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    getChallengeStart(state) {
      state.isLoading = true;
      state.error = null;
    },
    getChallengeSuccess(state, action) {
      state.isLoading = false;
      state.challengeData = action.payload;
    },
    getChallengeFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});
export const { getChallengeStart, getChallengeSuccess, getChallengeFailure } =
  challengeSlice.actions;
export const challengeDay = (state) => state.challenge.challengeData;
export default challengeSlice.reducer;
