import { Link, useLocation, useNavigate } from "react-router-dom";
import "./PhysicalChallengeDetails.css";
import { useEffect, useState } from "react";
import VedioPlayer from "../../../../../utils/VedioPlayer/VedioPlayer";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import SpeedIcon from "@mui/icons-material/Speed";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import xicon from "../../../../../assets/images/xicon.jpeg";
import tiktok from "../../../../../assets/images/tiktok.jpeg";
import Instagram from "../../../../../assets/images/instagram.jpeg";
import { getFromLocalStorage } from "../../../../../utils/local-storage";
import physicalPhoto from "../../../../../assets/images/physicalWithPlay.png";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import dumber from "../../../../../assets/images/dumper2.png";
import backImg from "../../../../../assets/images/image (1).png";
const PhysicalChallengeDetails = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const d = JSON.parse(getFromLocalStorage("localData"));
  const { intense_physical, moderate_physical } = d;
  const [PhysicalChallengeData, setPhysicalChallengeData] = useState();
  useEffect(() => {
    setPhysicalChallengeData({ intense_physical, moderate_physical });
  }, []);
  const [playerData, setPlayerData] = useState({
    CloseFunction: closeModal,
    vedioData: PhysicalChallengeData?.intense_physical?.video,
  });
  const backButton = () => {
    navigate("/ChallengePages");
  };
  const [challengeData, setChallengeData] = useState();
  const [moderateClick, setModerateClick] = useState(true);
  const [intenseClick, setIntenseClick] = useState(false);
  const ChangeCLick = () => {
    setModerateClick(!moderateClick);
    setIntenseClick(!intenseClick);
  };
  useEffect(() => {
    setChallengeData({
      backgroundImage: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.image
        : PhysicalChallengeData?.intense_physical?.image,
      day: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.day
        : PhysicalChallengeData?.intense_physical?.day,
      time: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.duration
        : PhysicalChallengeData?.intense_physical?.duration,
      workOutName: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.workout_name
        : PhysicalChallengeData?.intense_physical?.workout_name,
      workOutEquipment: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.required_equipments
        : PhysicalChallengeData?.intense_physical?.required_equipments,
      workOutTitle: moderateClick ? "Moderate" : "Intense",
      workOutName: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.workout_name
        : PhysicalChallengeData?.intense_physical?.workout_name,
      workOutDescription: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.description
        : PhysicalChallengeData?.intense_physical?.description,
      instructorName: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.name
        : PhysicalChallengeData?.intense_physical?.instructor?.name,
      instructorImage: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.image
        : PhysicalChallengeData?.intense_physical?.instructor?.image,
      instructorDescription: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.description
        : PhysicalChallengeData?.intense_physical?.instructor?.description,
      instructorInstaLink: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.ig_link
        : PhysicalChallengeData?.intense_physical?.instructor?.ig_link,
      instructorTwitterLink: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.twitter_link
        : PhysicalChallengeData?.intense_physical?.instructor?.twitter_link,
      instructorTiktokLink: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.instructor?.tiktok_link
        : PhysicalChallengeData?.intense_physical?.instructor?.tiktok_link,
    });
    setPlayerData({
      CloseFunction: closeModal,
      vedioData: moderateClick
        ? PhysicalChallengeData?.moderate_physical?.video
        : PhysicalChallengeData?.intense_physical?.video,
    });
  }, [PhysicalChallengeData, moderateClick]);
  const navigate1 = useNavigate();
  const GratitudeClick = () => {
    navigate1("/ChallengePages/mental-challenge-detail");
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const MentalChallengeClick = () => {
    navigate1("/ChallengePages/mental-challenge-detail");
  };
  return (
    <div>
      {isDesktop ? (
        <div className="flex">
          <div className="w-2/12 ">
            <div
              onClick={() => navigate(-1)}
              className="overlap-groupPhyDetails"
            >
              <ExpandCircleDownIcon
                className="mt-[-33rem] ml-4"
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "2.0rem",
                  fill: "none",
                  stroke: "rgb(246, 170, 26)",
                  strokeWidth: "1.5px",
                }}
              />
            </div>
          </div>
          <div className="w-6/12">
            <div className="ml-[-4rem] mt-[2rem]">
              <div>
                <h1 className="text-2xl font-bold">Physical challenge</h1>
                <p className="text-sm mt-4">Challenge {challengeData?.day}</p>
              </div>
              {challengeData?.day &&
                parseInt(challengeData.day.match(/\d+/)[0]) % 2 !== 0 && (
                  <div className="flex  mt-[2rem] ">
                    <div
                      onClick={() => ChangeCLick()}
                      className="border-2 rounded-sm border-black p-2 w-[15rem] h-[2.20rem]"
                      style={{
                        borderImage:
                          "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
                        background: moderateClick
                          ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
                          : "",
                      }}
                    >
                      <h1
                        className={`${
                          moderateClick ? `toggleText` : `toggleRev`
                        }  text-center mt-[-.25rem]`}
                      >
                        Moderate
                      </h1>
                    </div>
                    <div
                      onClick={() => ChangeCLick()}
                      className="border-2 border-black rounded-sm p-2 w-[15rem] h-[2.20rem]"
                      style={{
                        borderImage:
                          "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
                        background: intenseClick
                          ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
                          : "",
                      }}
                    >
                      <h1
                        className={`${
                          intenseClick ? `toggleText` : `toggleRev`
                        } text-center mt-[-.25rem]`}
                      >
                        Intense
                      </h1>
                    </div>
                  </div>
                )}
              <div>
                <h1 className="text-xl mt-2 font-bold">
                  {challengeData?.workOutName}
                </h1>
                <div>
                  <h1 className="flex gap-2 mt-2">
                    <SpeedIcon></SpeedIcon> {challengeData?.workOutTitle}
                  </h1>
                  <h1 className="flex gap-2 mt-2">
                    <AccessTimeFilledIcon></AccessTimeFilledIcon>
                    {challengeData?.time}
                  </h1>
                  <h1 className="flex gap-2 mt-2">
                    <FitnessCenterIcon></FitnessCenterIcon>
                    {challengeData?.workOutName}
                  </h1>
                  <h1 className="flex gap-2 mt-2 ml-2">
                    <img
                      className="w-4"
                      src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"
                    ></img>
                    {challengeData?.workOutEquipment}
                  </h1>
                </div>
                <h1 className="mt-2 w-[35rem]">
                  {challengeData?.workOutDescription}
                </h1>
              </div>
              <div className="mt-4">
                <div className="flex gap-4">
                  <div>
                    <img
                      className="w-[12rem]"
                      src={challengeData?.instructorImage}
                    ></img>
                  </div>
                  <div className="mt-8">
                    <h1 className="text-2xl font-bold">
                      {challengeData?.instructorName}
                    </h1>
                    <div className="flex gap-4 mt-4">
                      <a
                        href={challengeData?.instructorTwitterLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={xicon}></img>
                      </a>
                      <a
                        href={challengeData?.instructorTiktokLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={tiktok}></img>
                      </a>
                      <a
                        href={challengeData?.instructorInstaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={Instagram}></img>
                      </a>
                    </div>
                    <h1 className="mt-5 w-[20rem]">
                      {challengeData?.instructorDescription}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-6/12 lg:w-7/12 xl:w-8/12 mt-[-2.75rem] mr-8">
            <img
              onClick={openModal}
              className="h-[40rem] w-full ml-12"
              src={physicalPhoto}
              alt="Gratitude Challenge"
            />
            {isModalOpen && (
              <VedioPlayer
                videoSource={playerData}
                isOpen={isModalOpen}
                onClose={closeModal}
              />
            )}
            <img
              className="absolute mt-[-37rem] ml-6 w-16 z-10"
              src={dumber}
              alt="Dumber"
            />
            <div
              onClick={() => MentalChallengeClick()}
              className="absolute bottom-0 right-0 mb-12 mr-4 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-lg shadow-lg card-body cardLeftBodyChallengeNext mt-[0rem] h-[5rem] p-[0rem]"
            >
              <div className="flex items-center h-full">
                <img
                  src={backImg}
                  className="h-full w-auto rounded-lg"
                  alt="Movie"
                />
                <div className="flex ml-2">
                  <div>
                    <h2 className=" text-white text-left">Mental</h2>
                    <h2 className=" text-white text-left">
                      Gratitude Challenge
                    </h2>
                  </div>
                  <ArrowForwardIosOutlinedIcon
                    style={{ color: "white" }}
                    className="ArrowRightDetailsPhy"
                  ></ArrowForwardIosOutlinedIcon>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div>
            <img
              className="w-full h-auto"
              src={challengeData?.backgroundImage}
            ></img>
            <ExpandCircleDownIcon
              onClick={backButton}
              className="mt-[-27rem] ml-4"
              style={{
                transform: "rotate(90deg)",
                fontSize: "2.0rem",
                fill: "none",
                stroke: "rgb(246, 170, 26)",
                strokeWidth: "1.5px",
              }}
            />
            <img
              className="mt-[-5rem] w-10 ml-4"
              alt="Group"
              src="https://c.animaapp.com/apIjDicq/img/group-11179@2x.png"
            />
            <PlayCircleFilledWhiteIcon
              onClick={openModal}
              className="mt-[-13rem] ml-[10rem]"
              sx={{
                fontSize: 55,
                color: "#f17430cc",
              }}
            ></PlayCircleFilledWhiteIcon>
            {isModalOpen && (
              <VedioPlayer
                videoSource={playerData}
                isOpen={isModalOpen}
                onClose={closeModal}
              />
            )}
          </div>
          <div>
            <h1 className="text-2xl ml-2 font-bold">Physical challenge</h1>
            <h1 className="ml-2">Challenge {challengeData?.day}</h1>
          </div>
          <div className="flex ml-2 mt-2 ">
            <div
              onClick={() => ChangeCLick()}
              className="border-2 rounded-sm border-black p-2 w-[11.5rem]"
              style={{
                borderImage:
                  "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
                background: moderateClick
                  ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
                  : "",
              }}
            >
              <h1
                className={`${
                  moderateClick ? `toggleText` : `toggleRev`
                }  text-center`}
              >
                Moderate
              </h1>
            </div>
            <div
              onClick={() => ChangeCLick()}
              className="border-2 border-black rounded-sm p-2 w-[11.5rem]"
              style={{
                borderImage:
                  "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
                background: intenseClick
                  ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
                  : "",
              }}
            >
              <h1
                className={`${
                  intenseClick ? `toggleText` : `toggleRev`
                } text-center`}
              >
                Intense
              </h1>
            </div>
          </div>
          <div className="mt-4">
            <h1 className="text-md ml-2 font-bold">
              {challengeData?.workOutName}
            </h1>
            <div className="flex flex-col gap-2 mt-3">
              <h1 className="ml-2">
                <SpeedIcon></SpeedIcon> {challengeData?.workOutTitle}
              </h1>
              <h1 className="ml-2">
                <AccessTimeFilledIcon></AccessTimeFilledIcon>{" "}
                {challengeData?.time}
              </h1>
              <h1 className="ml-2">
                <FitnessCenterIcon></FitnessCenterIcon>{" "}
                {challengeData?.workOutName}
              </h1>
              <div className="flex ml-3">
                <img
                  className="w-[1rem]"
                  src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"
                ></img>
                <h1 className="ml-2">{challengeData?.workOutEquipment}</h1>
              </div>
              {/* <h1 className="ml-[.75rem] flex gap-2">
                <img src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"></img>{" "}
                {challengeData?.workOutEquipment}
              </h1> */}
            </div>
          </div>
          <div className="mt-3">
            <h1 className="ml-2 mr-2">{challengeData?.workOutDescription}</h1>
          </div>
          <div className="ml-2 mt-[2rem]">
            <div className="flex gap-3">
              <img src={challengeData?.instructorImage}></img>
              <div className="mt-10">
                <h1 className="text-2xl font-bold">
                  {challengeData?.instructorName}
                </h1>
                <div className="flex gap-4 mt-4">
                  <a
                    href={challengeData?.instructorTwitterLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={xicon}></img>
                  </a>
                  <a
                    href={challengeData?.instructorTiktokLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={tiktok}></img>
                  </a>
                  <a
                    href={challengeData?.instructorInstaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={Instagram}></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="mb-[6rem] mt-[1rem] mr-2">
              <h1>{challengeData?.instructorDescription}</h1>
              <div
                className="border-2 border-black rounded-sm p-2 w-[22rem] mt-5"
                style={{
                  borderImage:
                    "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
                  background:
                    "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)",
                }}
              >
                <div onClick={GratitudeClick} className="flex justify-between">
                  <h1 className="text-white text-left">Gratitude Challenge</h1>
                  <img src="https://c.animaapp.com/apIjDicq/img/vector-71.svg"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default PhysicalChallengeDetails;
{
  /* <div className="flex justify-between ">
  <div className="w-1/3 mt-10 ml-10 mr-10 flex flex-row">
    <div onClick={() => navigate(-1)} className="overlap-group">
      <ExpandCircleDownIcon
        className="mt-[-33rem] ml-4"
        style={{
          transform: "rotate(90deg)",
          fontSize: "2.0rem",
          fill: "none",
          stroke: "rgb(246, 170, 26)",
          strokeWidth: "1.5px",
        }}
      />
    </div>
    <div className="ml-[4rem]">
      <div>
        <h1 className="text-2xl font-bold">Physical challenge</h1>
        <p className="text-sm mt-4">Challenge {challengeData?.day}</p>
      </div>
      <div className="flex  mt-2 ">
        <div
          onClick={() => ChangeCLick()}
          className="border-2 rounded-sm border-black p-2 w-[11rem] h-[2.5rem]"
          style={{
            borderImage:
              "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
            background: moderateClick
              ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
              : "",
          }}
        >
          <h1
            className={`${
              moderateClick ? `toggleText` : `toggleRev`
            }  text-center`}
          >
            Moderate
          </h1>
        </div>
        <div
          onClick={() => ChangeCLick()}
          className="border-2 border-black rounded-sm p-2 w-[11rem] h-[2.5rem]"
          style={{
            borderImage:
              "linear-gradient(to bottom, rgb(246, 170, 26), rgb(234, 58, 36)) 1",
            background: intenseClick
              ? "linear-gradient(180deg, rgb(246, 170, 26) 0%, rgb(234, 58, 36) 100%)"
              : "",
          }}
        >
          <h1
            className={`${
              intenseClick ? `toggleText` : `toggleRev`
            } text-center`}
          >
            Intense
          </h1>
        </div>
      </div>
      <div>
        <h1 className="text-xl mt-2 font-bold">{challengeData?.workOutName}</h1>
        <div>
          <h1 className="flex gap-2 mt-2">
            <SpeedIcon></SpeedIcon> {challengeData?.workOutTitle}
          </h1>
          <h1 className="flex gap-2 mt-2">
            <AccessTimeFilledIcon></AccessTimeFilledIcon> {challengeData?.time}
          </h1>
          <h1 className="flex gap-2 mt-2">
            <FitnessCenterIcon></FitnessCenterIcon> {challengeData?.workOutName}
          </h1>
          <h1 className="flex gap-2 mt-2">
            <img src="https://c.animaapp.com/apIjDicq/img/layer-1.svg"></img>{" "}
            {challengeData?.workOutEquipment}
          </h1>
        </div>
        <h1 className="mt-2">{challengeData?.workOutDescription}</h1>
      </div>
      <div className="mt-4">
        <div className="flex gap-2">
          <div>
            <img
              className="w-[12rem]"
              src={challengeData?.instructorImage}
            ></img>
          </div>
          <div className="mt-4">
            <h1 className="text-2xl font-bold">
              {challengeData?.instructorName}
            </h1>
            <div className="flex gap-4 mt-4">
              <a
                href={challengeData?.instructorTwitterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={xicon}></img>
              </a>
              <a
                href={challengeData?.instructorTiktokLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={tiktok}></img>
              </a>
              <a
                href={challengeData?.instructorInstaLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram}></img>
              </a>
            </div>
            <h1>{challengeData?.instructorDescription}</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="w-2/3 mt-10 mr-10 flex justify-center items-center">
    <img className="mt-[-19rem] ml-6 w-16 z-10" src={dumber}></img>
    <img
      className="mr-[-30rem] h-[35rem]"
      src={physicalPhoto}
      alt="Gratitude Challenge"
    ></img>
    <div className="mt-[32rem] ml-[4rem] card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeBodyChallengeDetailsBack">
      <img
        src="https://c.animaapp.com/QJzwa7Iu/img/mask-group-1@2x.png"
        alt="Movie"
        className="BodyChallengeImageDetails"
      />
      <PlayCircleFilledWhiteIcon
        onClick={openModal}
        className="mt-[-13rem] mr-[10rem]"
        sx={{
          fontSize: 55,
          color: "#f17430cc",
        }}
      ></PlayCircleFilledWhiteIcon>
      {isModalOpen && (
        <VedioPlayer
          videoSource={playerData}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      )}
      <div className="card-body cardLeftBodyChallengeNext">
        <h2 className="ml-[2.5rem] mt-[-1.5rem] text-white text-left">
          Mental
        </h2>
        <h2 className="ml-[2rem] text-white text-left">Gratitude Challenge</h2>
        <div className="flex flex-col justify-between  ArrowLeft">
          <ArrowForwardIosOutlinedIcon
            onClick={() => MentalChallengeClick()}
            style={{ color: "white" }}
            className="ArrowRightDetails"
          ></ArrowForwardIosOutlinedIcon>
        </div>
      </div>
    </div>
  </div>
</div>; */
}
