import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, matchPath } from "react-router-dom";
import "./Header.css";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
const Header = () => {
  const location = useLocation();
  const { pathname } = location;
  const navigate = useNavigate();
  const ProfileClick = () => {
    navigate("/Profile-Page");
  };
  const SettingClick = () => {
    navigate("/Setting");
  };
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [changePathName, setPathName] = useState(true);
  const [changePathName1, setPathName1] = useState(true);
  useEffect(() => {
    const match = matchPath("/EventPage/:id", pathname);
    if (
      pathname === "/ChallengePages/mental-challenge-detail" ||
      pathname === "/ChallengePages/physical-challenge-detail" ||
      pathname === "/ChallengePages/social-challenge-detail" ||
      match
    ) {
      setPathName(false);
    } else {
      setPathName(true);
    }

    if (
      pathname === "/ChallengePages" ||
      pathname === "/EventPage" ||
      pathname === "/Prizes-Page" ||
      pathname === "/How-it-works"
    ) {
      setPathName1(false);
    } else {
      setPathName1(true);
    }
  }, [pathname]);
  const manuItems1 = [
    { name: "/ChallengePages", text: "Home" },
    { name: "/Prizes-Page", text: "Prizes" },
    { name: "/EventPage", text: "Events" },
    { name: "/How-it-works", text: "How it Works" },
  ];
  const [selectedItem, setSelectedItem] = useState("Home");
  const handleClick = (itemName) => {
    setSelectedItem(itemName);
  };
  return (
    <div className={`m-11 ml-4 ${isDesktop&&`w-full bg-custom-f9f6f2 shadow-lg`}`} >
      {pathname === "/Setting" ? (
        <ArrowBackIosNewIcon
          onClick={() => navigate(-1)}
          className="personlogo"
          fontSize="large"
        ></ArrowBackIosNewIcon>
      ) : isDesktop ? (
        <div className="flex justify-between ">
          <ul className="flex mx-16 gap-8 mt-10">
            {manuItems1.map((item) => (
              <li
                key={item.name}
                style={{
                  fontWeight: "bold",
                  color:
                    selectedItem === item.text
                      ? "rgba(241, 116, 48, 1)"
                      : "rgba(29, 29, 31, 1)",
                  cursor: "pointer",
                }}
              >
                <Link
                  onClick={() => handleClick(item.text)}
                  to={`${item.name}`}
                >
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
          <PersonIcon
            onClick={ProfileClick}
            className="personlogo mr-[6rem] mb-[1.5rem]"
            fontSize="large"
          />
        </div>
      ) : changePathName ? (
        <div className="flex justify-between">
          <PersonIcon
            onClick={ProfileClick}
            className="personlogo"
            fontSize="large"
          />
          {changePathName1 ? (
            <SettingsIcon
              onClick={SettingClick}
              fontSize="large"
              className="personlogo"
            ></SettingsIcon>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Header;
