import { useEffect, useRef } from "react";
import { useRegisterMutation } from "../../features/auth/authAPI";
import { getFromLocalStorage } from "../../utils/local-storage";
import { useNavigate } from "react-router-dom";
import LoadingPage from "../Shared/LoadingPage/LoadingPage";
import toast, { Toaster } from "react-hot-toast";
const SignUpLoadingPage = () => {
  const [register, { isLoading, data, isSuccess, error: responseError }] =
    useRegisterMutation();
  const registerInitiatedRef = useRef(false);
  const navigate = useNavigate();
  const s = JSON.parse(getFromLocalStorage("step1"));
  const s1 = JSON.parse(getFromLocalStorage("step2"));
  const s2 = JSON.parse(getFromLocalStorage("step3"));
  const notify = (message) => toast(message);
  useEffect(() => {
    if (!registerInitiatedRef.current) {
      registerInitiatedRef.current = true;
      if (s1?.is_student === false) {
        register({
          born_year: s1.year,
          username: s.username,
          gender: s1.gender,
          is_student: s1.is_student,
          country: s2.city,
          state: s2.state,
          city: s2.city,
          email: s.email,
          password: s.pass,
        });
      } else if (s1?.is_student === true) {
        register({
          born_year: s1.year,
          username: s.username,
          gender: s1.gender,
          is_student: s1.is_student,
          university_name: s1.UniName,
          country: s2.city,
          state: s2.state,
          city: s2.city,
          email: s.email,
          password: s.pass,
        });
      }
    }
    if (data?.activation_token) {
      console.log("daaa::", data);
    }
    if (responseError?.data) {
      console.log(responseError?.data?.email[0]);
      notify(responseError?.data?.email[0])
      navigate('/signUp1')
    }
    if (isLoading) {
      console.log("Loading...");
    }
    if (isSuccess) {
      localStorage.clear();
      navigate("/login");
    }
  }, [
    register,
    s,
    s1,
    s2,
    registerInitiatedRef,
    isLoading,
    responseError,
    isSuccess,
    data,
    navigate,
  ]);

  return <LoadingPage></LoadingPage>;
};
export default SignUpLoadingPage;
