import { useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "./ViewAllBadges.css";
import diamond from "../../../../assets/images/Diamond@2x.png";

const ViewAllBadges = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="m-8">
        <ArrowBackIosNewIcon
          onClick={() => navigate(-1)}
          className="backButtonlogo"
          fontSize="large"
        ></ArrowBackIosNewIcon>
      </div>
      <div className="mt-5 ml-5">
        <h1 className="font-sans font-medium text-2xl">Badges</h1>
        <div className="mt-4">
          <h1 className="font-sans font-medium text-xl">Category 1</h1>
          <div className="flex mt-3 justify-start ml-3">
            <img width={100} src={diamond}></img>
            <img width={100} src={diamond}></img>
            <img
              className="filter grayscale opacity-50"
              width={100}
              src={diamond}
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewAllBadges;
