import Cookies from "js-cookie";
export const setCookies = (tokenName, token) => {
  Cookies.set(tokenName, token, { expires: 7 });
};
export const getCookies = (tokenName) => {
  return Cookies.get(tokenName);
};
export const clearCookies = () => {
  Cookies.remove("accessToken");
  Cookies.remove("refreshToken");
};
