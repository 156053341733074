// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logoutlogo {
  transform: rotate(180deg);
  color: #f17430;
}
.LineColor{
    color: #f17430;
}
.ToggleOff{
    color: #D9D9D9;
}
.ToggleOn{
    color: #f17430;
}
.signOutCSS{
    margin-left: -2.5rem;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SettingPage/Setting.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,cAAc;AAChB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,oBAAoB;AACxB","sourcesContent":[".Logoutlogo {\n  transform: rotate(180deg);\n  color: #f17430;\n}\n.LineColor{\n    color: #f17430;\n}\n.ToggleOff{\n    color: #D9D9D9;\n}\n.ToggleOn{\n    color: #f17430;\n}\n.signOutCSS{\n    margin-left: -2.5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
