import { useLocation, Link, useNavigate } from "react-router-dom";
import "./GratitudeChallenge.css";
import { useEffect, useState } from "react";
import Pie from "../../../../../utils/Pie";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import gratitudeChallengeDesktoppic from "../../../../../assets/images/MD2.png";
import flower from "../../../../../assets/images/flower.png";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { getFromLocalStorage } from "../../../../../utils/local-storage";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import backImg from "../../../../../assets/images/image (2).png";
const GratitudeChallenge = () => {
  const d = JSON.parse(getFromLocalStorage("localData"));
  const navigate = useNavigate();
  const { mental: MentalChallengeData } = d;
  const string = MentalChallengeData?.description;
  const firstSentence = string.split("?")[0].trim() + "?";
  const restOfString = string.slice(firstSentence.length).trim();
  const [audioElement, setAudioElement] = useState(null);
  const [duration, setDuration] = useState("0:00");
  const [currentTime, setCurrentTime] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  useEffect(() => {
    const audio = document.getElementById("audioPlayer");
    setAudioElement(audio);
    if (audio) {
      audio.addEventListener("loadedmetadata", () => {
        const formattedDuration = formatTime(audio.duration);
        setTotalDuration(audio.duration);
        setDuration(formattedDuration);
      });
      audio.addEventListener("timeupdate", updateTime);
    }
    return () => {
      if (audio) {
        audio.removeEventListener("timeupdate", updateTime);
      }
    };
  }, [duration]);
  const [playPause, setPlayPause] = useState(true);
  const playAudio = () => {
    if (playPause === true) {
      audioElement.play();
    } else if (playPause === false) {
      audioElement.pause();
    }
    setPlayPause(!playPause);
  };
  const [mute, setMute] = useState(true);
  const muteSound = () => {
    setMute(!mute);
    audioElement.muted = !audioElement.muted;
  };

  const reStart = () => {
    audioElement.currentTime = 0;
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedTime = minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    return formattedTime;
  };

  const updateTime = () => {
    if (audioElement && audioElement.duration) {
      const remainingTime = audioElement.duration - audioElement.currentTime;
      const formattedTime = formatTime(remainingTime);
      setCurrentTime(remainingTime);
      setDuration(formattedTime);
    }
  };
  const [percentage, setPercentage] = useState(100);
  useEffect(() => {
    if (currentTime !== 0) {
      const percentage = (currentTime / parseInt(totalDuration)) * 100;
      setPercentage(Math.floor(percentage));
    }
  }, [totalDuration, currentTime]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);
  const navigate1 = useNavigate();
  const MentalChallengeClick = () => {
    navigate1("/ChallengePages/social-challenge-detail");
  };
  return (
    <div>
      {isDesktop ? (
        <div className="flex">
          <div className="w-2/12 ">
            <div
              onClick={() => navigate(-1)}
              className="overlap-groupPhyDetails"
            >
              <ExpandCircleDownIcon
                className="mt-[-33rem] ml-4"
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "2.0rem",
                  fill: "none",
                  stroke: "rgb(246, 170, 26)",
                  strokeWidth: "1.5px",
                }}
              />
            </div>
          </div>
          <div className="w-6/12 ml-[-8rem] mt-[2rem]">
            <div>
              <h1 className="text-2xl font-bold">
                {MentalChallengeData?.title}
              </h1>
              <p className="text-sm">Challenge {MentalChallengeData?.day}</p>
              <h1 className="text-xl mt-5">{firstSentence}</h1>
            </div>
            <div className="flex">
              <Pie percentage={percentage} duration={duration}></Pie>
              <div className="flex mt-[4rem] gap-4">
                {mute ? (
                  <VolumeUpIcon
                    onClick={muteSound}
                    sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
                  ></VolumeUpIcon>
                ) : (
                  <VolumeOffIcon
                    onClick={muteSound}
                    sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
                  ></VolumeOffIcon>
                )}
                {playPause ? (
                  <PlayArrowIcon
                    onClick={playAudio}
                    sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
                  />
                ) : (
                  <PauseIcon
                    onClick={playAudio}
                    sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
                  />
                )}
                <img
                  className="mt-[-3rem]"
                  onClick={reStart}
                  src="https://c.animaapp.com/zW66uJEk/img/vector.svg"
                />
              </div>
              <audio id="audioPlayer">
                <source
                  src={MentalChallengeData?.voice_recording}
                  type="audio/mpeg"
                ></source>
              </audio>
            </div>
            <div>
              <h1 className="text-xl mt-5  w-[35rem]">{restOfString}</h1>
            </div>
          </div>
          <div>
            <div className="relative  mt-[-2.85rem] mr-[2rem]">
              <img
                className="h-[40rem] w-full ml-12"
                src={gratitudeChallengeDesktoppic}
                alt="Gratitude Challenge"
              />

              <img
                className="absolute mt-[-37rem] ml-6 w-16 z-10"
                src={flower}
                alt="Dumber"
              />
              <div
                onClick={() => MentalChallengeClick()}
                className="absolute bottom-0 right-0 mb-[-2rem]  mr-4 bg-gradient-to-br from-orange-500 to-yellow-500 rounded-lg shadow-lg card-body  h-[5rem] p-[0rem]"
              >
                <div className="flex  items-center h-full">
                  <img
                    src={backImg}
                    className="h-full w-auto rounded-lg"
                    alt="Movie"
                  />
                  <div className="flex ml-2">
                    <div>
                      <h2 className=" text-white text-left">Social</h2>
                      <h2 className=" text-white text-left">Act of Kindness</h2>
                    </div>

                    <ArrowForwardIosOutlinedIcon
                      style={{ color: "white" }}
                      className="ArrowRightDetailsPhy"
                    ></ArrowForwardIosOutlinedIcon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="iphoneGrat">
          <div className="div">
            <div
              className="overlapgratitue w-full h-auto"
              style={{
                backgroundImage: `url(${MentalChallengeData?.image})`,
              }}
            >
              <img
                className="group w-full h-auto"
                alt="Group"
                src="https://c.animaapp.com/zW66uJEk/img/group-11182@2x.png"
              />
              {/* <div onClick={() => navigate(-1)} className="overlap-group">
                <div className="ellipse" />
                <img
                  className="vector"
                  alt="Vector"
                  src="https://c.animaapp.com/zW66uJEk/img/vector-72.svg"
                />
              </div> */}
              <ExpandCircleDownIcon
                onClick={() => navigate(-1)}
                className="mt-[2rem] ml-4"
                style={{
                  transform: "rotate(90deg)",
                  fontSize: "2.0rem",
                  fill: "none",
                  stroke: "rgb(246, 170, 26)",
                  strokeWidth: "1.5px",
                }}
              />
            </div>
            <div className="overlap-2">
              <div className="text-wrapper">{MentalChallengeData?.title}</div>
              <div className="overlap-wrapper">
                <div className="div-wrapper">
                  <div className="text-wrapper-2">?</div>
                </div>
              </div>
            </div>
            <div className="text-wrapper-3">
              Challenge {MentalChallengeData?.day}
            </div>
            <div className="mt-[30rem]">
              <p className="firstSentence ml-3">{firstSentence}</p>
              <p className="restOfString ml-3 mt-3">{restOfString}</p>
            </div>
            <div className="overlap-3">
              <div className="subtract">
                <Pie percentage={percentage} duration={duration}></Pie>
              </div>
            </div>
            <div className="overlap-group-wrapper">
              {playPause ? (
                <PlayArrowIcon
                  onClick={playAudio}
                  sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
                />
              ) : (
                <PauseIcon
                  onClick={playAudio}
                  sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
                />
              )}
            </div>
            <div className="group-2">
              {mute ? (
                <VolumeUpIcon
                  onClick={muteSound}
                  sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
                ></VolumeUpIcon>
              ) : (
                <VolumeOffIcon
                  onClick={muteSound}
                  sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
                ></VolumeOffIcon>
              )}
            </div>
            <img
              onClick={reStart}
              className="vector-2"
              alt="Vector"
              src="https://c.animaapp.com/zW66uJEk/img/vector.svg"
            />
            <audio id="audioPlayer">
              <source
                src={MentalChallengeData?.voice_recording}
                type="audio/mpeg"
              ></source>
            </audio>
            <Link to="/ChallengePages/social-challenge-detail">
              <div className="overlap-4">
                <div className="rectangle" />
                <div className="text-wrapper-7">Act of kindness</div>
                <img
                  className="vector-3"
                  alt="Vector"
                  src="https://c.animaapp.com/zW66uJEk/img/vector-71.svg"
                />
              </div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
export default GratitudeChallenge;
//  <div className="flex justify-between ">
//           <div className="w-1/3 mt-10 ml-10 mr-10">
//             <div className="overlap-group">
//               <ExpandCircleDownIcon
//                 onClick={() => navigate(-1)}
//                 className="mt-[-33rem] ml-4"
//                 style={{
//                   transform: "rotate(90deg)",
//                   fontSize: "2.0rem",
//                   fill: "none",
//                   stroke: "rgb(246, 170, 26)",
//                   strokeWidth: "1.5px",
//                 }}
//               />
//             </div>
//             <div className="">
//               <h1 className="text-2xl font-bold">
//                 {MentalChallengeData?.title}
//               </h1>
//               <p className="text-sm">Challenge {MentalChallengeData?.day}</p>
//               <h1 className="text-xl mt-5">{firstSentence}</h1>
//             </div>
//             <div className="flex">
//               <Pie percentage={percentage} duration={duration}></Pie>
//               <div className="flex mt-[4rem] gap-4">
//                 {mute ? (
//                   <VolumeUpIcon
//                     onClick={muteSound}
//                     sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
//                   ></VolumeUpIcon>
//                 ) : (
//                   <VolumeOffIcon
//                     onClick={muteSound}
//                     sx={{ fontSize: 35, color: "rgba(241,116,48,1)" }}
//                   ></VolumeOffIcon>
//                 )}
//                 {playPause ? (
//                   <PlayArrowIcon
//                     onClick={playAudio}
//                     sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
//                   />
//                 ) : (
//                   <PauseIcon
//                     onClick={playAudio}
//                     sx={{ fontSize: 40, color: "rgba(241,116,48,1)" }}
//                   />
//                 )}
//                 <img
//                   className="mt-[-3rem]"
//                   onClick={reStart}
//                   src="https://c.animaapp.com/zW66uJEk/img/vector.svg"
//                 />
//               </div>
//               <audio id="audioPlayer">
//                 <source
//                   src={MentalChallengeData?.voice_recording}
//                   type="audio/mpeg"
//                 ></source>
//               </audio>
//             </div>
//             <div>
//               <h1 className="text-xl mt-5">{restOfString}</h1>
//             </div>
//           </div>
//           <div className="w-2/3 mt-10 mr-10 flex justify-center items-center">
//             <img className="mt-[-19rem] ml-3 z-10" src={flower}></img>
//             <img
//               className="mr-[-30rem] h-[30rem]"
//               src={gratitudeChallengeDesktoppic}
//               alt="Gratitude Challenge"
//             ></img>
//             <div className="mt-[25rem] mr-[6rem] card card-side bg-gradient-to-br from-orange-500 to-yellow-500 shadow-xl cardSizeBodyChallengeDetails">
//               <img
//                 src="https://c.animaapp.com/QJzwa7Iu/img/mask-group-1@2x.png"
//                 alt="Movie"
//                 className="BodyChallengeImageDetails"
//               />
//               <div className="card-body cardLeftBodyChallenge">
//                 <h2 className="ml-[2.5rem] mt-[-1.5rem] text-white text-left">
//                   Social
//                 </h2>
//                 <h2 className="ml-[2rem] text-white text-left">
//                   Act of Kindness
//                 </h2>
//                 <div className="flex flex-col justify-between  ArrowLeft">
//                   <ArrowForwardIosOutlinedIcon
//                     onClick={() => MentalChallengeClick()}
//                     style={{ color: "white" }}
//                     className="ArrowRightDetails"
//                   ></ArrowForwardIosOutlinedIcon>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
