import { createBrowserRouter } from "react-router-dom";
import HomePage from "../../Pages/HomePage/Homepage";
import LoginPage from "../../Pages/Login/Login";
import CreateAccount1 from "../../Pages/SignUp/CreateAccount1";
import CreateAccount2 from "../../Pages/SignUp/CreateAccount2";
import CreateAccount3 from "../../Pages/SignUp/CreateAccount3";
import Termsandconditions from "../../Pages/SignUp/Termsandconditions";
import SignUpLoadingPage from "../../Pages/SignUp/SignUpLoadingPage";
import FirstPage from "../../Pages/FirstPage/FirstPage";
import Main from "../../Pages/Layout/Main";
import EventPage from "../../Pages/EventPage/EventPage";
import PhysicalChallengeDetails from "../../Pages/FirstPage/TodaysChallenge/BodyChallenge/PhysicalChallengeDetails/PhysicalChallengeDetails";
import Secondary from "../../Pages/Layout/Secondary";
import GratitudeChallenge from "../../Pages/FirstPage/TodaysChallenge/MentalChallenge/GratitudeChallenge/GratitudeChallenge";
import SocialChallengeDetails from "../../Pages/FirstPage/TodaysChallenge/SocialChallenge/SocialChallengeDetails/SocialChallengeDetails";
import DailyPrizeDetails from "../../Pages/FirstPage/DailyPrizes/DailyPrizeDetails/DailyPrizeDetails";
import HowItWorks from "../../Pages/HowItWorks/HowItWorks";
import PrizesPage from "../../Pages/PrizesPage/PrizesPage";
import ProfilePage from "../../Pages/ProfilePage/ProfilePage";
import NotFoundPage from "../../Pages/Shared/NotFoundPage/NotFoundPage";
import Setting from "../../Pages/SettingPage/Setting";
import ViewAllBadges from "../../Pages/ProfilePage/Badges/ViewAllBadges/ViewAllBadges";
import EventDetails from "../../Pages/EventPage/EventDetails/EventDetails";
import ChangeProfile from "../../Pages/SettingPage/ChangeProfile/ChangeProfile";
import ChangePassword from "../../Pages/SettingPage/ChangeProfile/ChangePassword/ChangePassword";
const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage></HomePage>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/login",
    element: <LoginPage></LoginPage>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/signUp1",
    element: <CreateAccount1></CreateAccount1>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/signUp2",
    element: <CreateAccount2></CreateAccount2>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/signUp3",
    element: <CreateAccount3></CreateAccount3>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/tearms-condition",
    element: <Termsandconditions></Termsandconditions>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/loading",
    element: <SignUpLoadingPage></SignUpLoadingPage>,
    errorElement: <NotFoundPage></NotFoundPage>,
  },
  {
    path: "/Setting",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/Setting",
        element: <Setting></Setting>,
      },
    ],
  },
  {
    path: "/ChangeProfile",
    errorElement: <NotFoundPage></NotFoundPage>,
    element: <ChangeProfile></ChangeProfile>,
  },
  {
    path: "/ChangePassword",
    errorElement: <NotFoundPage></NotFoundPage>,
    element: <ChangePassword></ChangePassword>,
  },
  {
    path: "/ChallengePages",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/ChallengePages",
        element: <FirstPage></FirstPage>,
      },
    ],
  },
  {
    path: "/ChallengePages/physical-challenge-detail",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/ChallengePages/physical-challenge-detail",
        element: <PhysicalChallengeDetails></PhysicalChallengeDetails>,
      },
    ],
  },
  {
    path: "/ChallengePages/mental-challenge-detail",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/ChallengePages/mental-challenge-detail",
        element: <GratitudeChallenge></GratitudeChallenge>,
      },
    ],
  },
  {
    path: "/ChallengePages/social-challenge-detail",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/ChallengePages/social-challenge-detail",
        element: <SocialChallengeDetails></SocialChallengeDetails>,
      },
    ],
  },
  {
    path: "/ChallengePages/daily-prize-details",
    element: <Secondary></Secondary>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/ChallengePages/daily-prize-details",
        element: <DailyPrizeDetails></DailyPrizeDetails>,
      },
    ],
  },
  {
    path: "/EventPage",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/EventPage",
        element: <EventPage></EventPage>,
      },
      {
        path: "/EventPage/:id",
        element: <EventDetails></EventDetails>,
      },
    ],
  },
  {
    path: "/How-it-works",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/How-it-works",
        element: <HowItWorks></HowItWorks>,
      },
    ],
  },
  {
    path: "/Prizes-Page",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/Prizes-Page",
        element: <PrizesPage></PrizesPage>,
      },
    ],
  },
  {
    path: "/Profile-Page",
    element: <Main></Main>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/Profile-Page",
        element: <ProfilePage></ProfilePage>,
      },
    ],
  },
  {
    path: "/View-All-Badges",
    element: <Secondary></Secondary>,
    errorElement: <NotFoundPage></NotFoundPage>,
    children: [
      {
        path: "/View-All-Badges",
        element: <ViewAllBadges></ViewAllBadges>,
      },
    ],
  },
]);
export default router;
