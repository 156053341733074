import React from "react";
import { Canvas, useLoader } from "react-three-fiber";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader";
import { OrbitControls, Environment, Text } from "@react-three/drei";
import { red } from "@mui/material/colors";
import { Euler } from "three";
import model from "../../../../assets/3D-Model/harledson_XYN.glb";
import model1 from "../../../../assets/3D-Model/Event1.glb";
import model2 from "../../../../assets/3D-Model/Event2.glb";
import model3 from "../../../../assets/3D-Model/1000W.glb";
import model4 from "../../../../assets/3D-Model/3 Spots.glb";
const Scene = ({ data }) => {
  let modelPath = model4; 
  switch (data) {
    case "model1":
      modelPath = model1;
      break;
    case "model2":
      modelPath = model2;
      break;
    default:
      modelPath = model3; 
      break;
  }
  const gltf = useLoader(GLTFLoader, modelPath);
  return (
    <Canvas
      flat
      linear
      camera={{ position: [0, -1.5, -1.5] }}
      style={{ background: "#00000555" }}
      gl={{
        antialias: true,
        alpha: true,
      }}
    >
      <directionalLight position={[0, 1, 1]} intensity={8} />
      <directionalLight position={[0, 0.2, 0.2]} intensity={7} />
      <directionalLight
        color={"#FFF3BC"}
        position={[0.4, -0.5, -0.5]}
        intensity={1}
      />
      <directionalLight
        color={"#FFF3BC"}
        position={[-2, 0, -1]}
        intensity={2}
      />
      <directionalLight color={"#FFF3BC"} position={[2, 0, 0]} intensity={1} />
      <directionalLight color={"#FFF3BC"} position={[0, 2, 0]} intensity={3} />
      <directionalLight
        color={"#FFF3BC"}
        position={[-2, -2, -1]}
        intensity={3}
      />
      <directionalLight
        color={"#FFF3BC"}
        scale={300}
        position={[0, 0, -1]}
        intensity={8}
      />
      <ambientLight intensity={10} color={"#FFF3BC"} />
      <directionalLight
        color={"#FFF3BC"}
        position={[0, -0.5, 0.5]}
        intensity={3}
      />
      <directionalLight
        color={"#FFF3BC"}
        position={[0, -0, -14.4]}
        intensity={1}
      />

      <primitive object={gltf.scene} dispose={null} />
      <OrbitControls
        minPolarAngle={Math.PI / 2}
        maxPolarAngle={Math.PI / 2}
        autoRotate
        enableZoom={false}
        rotation={Euler.DEFAULT_ORDER}
        autoRotateSpeed={10}
      />
    </Canvas>
  );
};

export default Scene;
