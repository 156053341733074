import React, { useEffect, useState } from "react";
import "./MobileNavBar.css";
import { FaHome } from "react-icons/fa";
import { FaInfoCircle } from "react-icons/fa";
import { FaCalendarCheck } from "react-icons/fa";
import { FaTrophy } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
const MobileNavBar = () => {
  const location = useLocation();
  const { pathname } = location;
  const [FaHomeCSS, setFaHomeCSS] = useState(false);
  const [FaCalendarCheckCSS, setFaCalendarCheckCSS] = useState(false);
  const [FaInfoCircleCSS, setFaInfoCircleCSS] = useState(false);
  const [FaTrophyCSS, setFaTrophyCSS] = useState(false);
  useEffect(() => {
    if (pathname.includes("ChallengePages")) {
      setFaHomeCSS(true);
      setFaCalendarCheckCSS(false);
      setFaInfoCircleCSS(false);
      setFaTrophyCSS(false);
    } else if (pathname.includes("EventPage")) {
      setFaCalendarCheckCSS(true);
      setFaHomeCSS(false);
      setFaInfoCircleCSS(false);
      setFaTrophyCSS(false);
    } else if (pathname.includes("How-it-works")) {
      setFaInfoCircleCSS(true);
      setFaHomeCSS(false);
      setFaCalendarCheckCSS(false);
      setFaTrophyCSS(false);
    } else if (pathname.includes("Prizes-Page")) {
      setFaInfoCircleCSS(false);
      setFaHomeCSS(false);
      setFaCalendarCheckCSS(false);
      setFaTrophyCSS(true);
    }
  }, [pathname]);

  return (
    <div>
      <div className="container_Nav">
        <Link to={"/ChallengePages"} className="element">
          <FaHome
            size={35}
            className={FaHomeCSS ? `iconActive` : `icon`}
          ></FaHome>
        </Link>
        <Link to={"/Prizes-Page"} className="element">
          <FaTrophy
            size={35}
            className={FaTrophyCSS ? `iconActive` : `icon`}
          ></FaTrophy>
        </Link>
        <Link to={"/EventPage"} className="element">
          <FaCalendarCheck
            size={35}
            className={FaCalendarCheckCSS ? `iconActive` : `icon`}
          ></FaCalendarCheck>
        </Link>
        <Link to={"/How-it-works"} className="element">
          <FaInfoCircle
            size={35}
            className={FaInfoCircleCSS ? `iconActive` : `icon`}
          ></FaInfoCircle>
        </Link>
      </div>
    </div>
  );
};

export default MobileNavBar;
