import "./PrizesPage.css";
import goldMedel from "../../assets/images/golden medal.png";
import bronzeMedal from "../../assets/images/bronze medal no color.png";
import silverMedel from "../../assets/images/silver medal.png";
import medalImg from "../../assets/images/MedalImg.png";
import { useEffect, useState } from "react";
const PrizesPage = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024);
  const [isTablet, setIsTablet] = useState(
    window.innerWidth <= 1024 && window.innerWidth > 768
  );

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1024);
      setIsTablet(window.innerWidth <= 1024 && window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div
      className={`prizes-page-container ${
        isDesktop ? `mt-[-3rem]` : `mt-[-5rem]`
      }`}
    >
      <div
        className={`text-wrapperPrizesPage ${
          isDesktop ? `ml-[-65rem] mt-[-26rem]` : `ml-[1rem] mt-[-35rem]`
        }`}
      >
        Prizes - Lorem ipsum
      </div>
      <div
        className={`grid  gap-4  ${
          isDesktop ? `mt-[8rem]` : `mt-[-5rem] grid-cols-1`
        }`}
      >
        <img src={medalImg} className="w-[22.5rem] h-[22.5rem]"></img>
      </div>
    </div>
  );
};

export default PrizesPage;
{
  /* <div className="overlap-groupCardPrizesPage" key="1">
          <div className="text-wrapper-2PrizesPage">Medals</div>
          <p className="pHowitworksParaPrizesPage">
            How to earn the medals, how to keep track of your progress and so
            on. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Phasellus elit mauris, placerat eu metus id, sagittis auctor nibh.
          </p>
          <div className="madelImagePrizesPage bg-blue-500 rounded-t-xl  flex">
            <img
              className="madelSingleImage"
              src={goldMedel}
              alt="Gold Medal"
            ></img>
            <img
              className="madelSingleImage"
              src={bronzeMedal}
              alt="Bronze Medal"
            ></img>
            <img
              className="madelSingleImage"
              src={silverMedel}
              alt="Silver Medal"
            ></img>
          </div>
        </div> */
}
