import React from "react";
const Circle = ({ colour, pct }) => {
  // console.log("test aws");
  const r = 50;
  const circ = 2 * Math.PI * r;
  const strokePct = ((100 - pct) * circ) / 100;
  return (
    <circle
      r={r}
      cx={120}
      cy={80}
      fill="transparent"
      stroke={strokePct !== circ ? colour : ""}
      strokeWidth={".2rem"}
      strokeDasharray={circ}
      strokeDashoffset={pct ? strokePct : 0}
      strokeLinecap="round"
    ></circle>
  );
};

const Text = ({ percentage }) => {
  return (
    <text
      x="50%"
      y="50%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize={"1.5em"}
    >
      0{percentage}
    </text>
  );
};

const Pie = ({ percentage, duration, image }) => {
  return (
    <svg width={150} height={150}>
      <g transform={`rotate(-90 ${"100 100"})`}>
        <Circle
          colour="linear-gradient(
    180deg,
    rgb(234, 58, 36) 0%,
    rgb(246, 170, 26) 100%
  )"
        />
        <Circle
          colour={["rgba(246,170,26,1)", "rgba(255,0,0,1)", "rgba(0,0,255,1)"]}
          pct={percentage}
        />
      </g>
      {image ? (
        <image xlinkHref={image} width={100} height={100} x={30} y={30}></image>
      ) : (
        <Text percentage={duration}></Text>
      )}
    </svg>
  );
};

export default Pie;
